import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from "react-router";
import { useTable, useSortBy } from 'react-table';

import CustomDatePicker from './date-picker';
import AddTransactionButton from './add-transaction-button';
import { setDateSettings, setFullDateSettings, formatDateForQuery, startOfMonth } from '../../helpers/cycle';
import eventEmitter from '../../helpers/eventEmitter'
import { Link } from 'react-router-dom';
import ExportCsvButton from '../general/export-csv-button';

function LedgerDetail(props) {
    const [userId, setUserId] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [salesRows, setSalesRows] = useState([]);
    const [transactionsRows, setTransactionsRows] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [summary, setSummary] = useState({});
    const transactionsColumns = useMemo(() => [
        {
            accessor: 'kind',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Kind',
            Cell: props => {
                let retVal = <><span style={{ display: 'block' }} className="label label-default">{props.value}</span></>
                if (props.row.original.payout) {
                    retVal = <><span style={{ display: 'block' }} className="label label-primary">payout</span></>
                }
                return retVal;
            }
        }, {
            accessor: 'created_at',
            headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Created On',
            Cell: props => <span>{new Date(props.value).toLocaleString()}</span>
        }, {
            accessor: 'description',
            className: 'text-left',
            Header: 'Description',
            Cell: props => <span>{props.value !== 'null' ? props.value : '<description not found>'}</span>
        }, {
            accessor: 'amount',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Amount</span>,
            Cell: props => {
                let retVal = <><span className="text-right" style={{ color: props.value > 0 ? 'chartreuse' : 'red' }}>${(props.value / 100).toFixed(2)}</span></>
                if (props.row.original.payout) {
                    retVal = <><span className="text-right" style={{ color: 'chartreuse' }}>${(props.value / 100).toFixed(2)}</span></>
                }
                return retVal;
            },
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.values.amount + sum, 0),
                    [info.rows]
                )

                return <>Total: <span style={{ float: 'right' }}>${(total / 100).toFixed(2)}</span></>
            },
        }
    ], []);
    const salesColumns = useMemo(() => [
        {
            accessor: 'product_title',
            Header: 'Product Title',
            //Cell: props => { }
        }, {
            accessor: 'start_date',
            Header: 'Pay Period',
            Cell: props => <span>{`${new Date(startDate).toLocaleDateString()} to ${new Date(endDate).toLocaleDateString()}`}</span>
        }, {
            accessor: 'quantity',
            Header: () => <span style={{float: 'right'}}>Total Sold</span>,
        }
    ], [startDate, endDate])


    async function loadData(stDate = null, enDate = null) {
        let id = props.profile.app_metadata.role !== 'admin' ? props.profile.user_id : null;
        let { userId } = props.match.params;
        let sDate = stDate ? stDate : startDate;
        let eDate = enDate ? enDate : endDate;
        if (id === null) {
            id = userId
        }
        if (id) {
            eventEmitter.emit('setLoading', true);
            let response = await fetch(`/api/ledgerDetails/${id}/${formatDateForQuery(sDate, false)}/${formatDateForQuery(eDate, true)}`);
            let result = await response.json();
            let newSummary = summary;
            if (result.summary[0]) {
                newSummary = result.summary[0];
            }
            setSummary(newSummary);
            setTransactionsRows(result.transactions);
            setSalesRows(result.sales);
            setDataLoaded(true);
            setUserId(id);
            setStartDate(sDate);
            setEndDate(eDate);
            eventEmitter.emit('setLoading', false);
        }
    }

    useEffect(async () => {
        if (!props.isLoading) {
            let eDate = new Date();
            eDate.setDate(eDate.getDate() + 1);
            eDate = setDateSettings(eDate)

            let sDate = new Date();
            sDate = startOfMonth(sDate);
            sDate = setFullDateSettings(sDate);
            await loadData(sDate, eDate);
        }
    }, [])

    function Summary() {
        return (
            <Row className="ledger-detail-summary">
                <Col sm={3}>
                    <div className="well">
                        <h5>Starting Balance</h5>
                        <p>${(summary.startingbalance / 100).toFixed(2) || (0).toFixed(2)}</p>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="well">
                        <h5>Gross Expenses</h5>
                        <p>${(summary.expenses / 100).toFixed(2) || (0).toFixed(2)}</p>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="well">
                        <h5>Gross Income</h5>
                        <p>${(summary.profit / 100).toFixed(2) || (0).toFixed(2)}</p>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="well">
                        <h5>Net Balance</h5>
                        <p>${(summary.net / 100).toFixed(2) || (0).toFixed(2)}</p>
                    </div>
                </Col>
            </Row>)
    }

    function Table({ columns, data, tablenum }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            footerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        }, useSortBy)

        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    <ExportCsvButton
                        name={tablenum === 0 ? 'Transaction Detail' : 'Sales'}
                        tableRows={rows}
                        formatters={tablenum === 0 ? ['excluded', '', '', 'money', 'date', 'excluded', ''] : ['', '', '', '', '','']}
                    />
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            {footerGroups.map(group => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map(column => (
                                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }

    let retVal = (
        <>
        </>
    )

    if (!props.isLoading && dataLoaded) {
        retVal = (
            <>
                <Row className="sub-header p-5">
                    <Col xs="12" sm="5">
                        <h1 className="text-left">Ledger for {summary.user_name}</h1>
                    </Col>
                    <Col xs="12" sm="7">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className='mr-2'>
                                <CustomDatePicker onChange={loadData} cycleTime={moment(startDate).toString()} settings={props.settings} />
                            </div>

                            {(props.profile.app_metadata.role === "admin") &&
                                <div className='mr-2'>
                                    <Link className="btn btn-default" to={`/admin/inventory/${props.match.params.userId}`}>Inventory</Link>
                                </div>
                            }
                            {(props.profile.app_metadata.role === "admin") &&
                                <div className='mr-2'>
                                    <Link className="btn btn-default" to={`/admin/expenses/${props.match.params.userId}`}>Expenses</Link>
                                </div>
                            }
                            {(props.profile.app_metadata.role === "admin") &&
                                <div className='mr-2'>
                                    <Link className="btn btn-default" to={`/admin/income/${props.match.params.userId}`}>Income</Link>
                                </div>
                            }
                            {(props.profile.app_metadata.role === "admin") &&
                                <div className='mr-2'>
                                    <AddTransactionButton kind="Expense" onChange={loadData} userId={props.match.params.userId} />
                                </div>
                            }
                            {(props.profile.app_metadata.role === "admin") &&
                                <div className='mr-2'>
                                    <AddTransactionButton kind="Credit" onChange={loadData} userId={props.match.params.userId} />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <div className="m-4">
                    <h3 className="text-left">Summary</h3>
                    <Summary />
                </div>
                <div className="m-4">
                    <h3 className="text-left">Transactions</h3>
                    <Table columns={transactionsColumns} data={transactionsRows} tablenum={0}/>
                </div>
                <div className="m-4">
                    <h3 className="text-left">Sales</h3>
                    <Table columns={salesColumns} data={salesRows} tablenum={1}/>
                </div>
            </>
        )
    }

    return retVal;
}

export default withRouter(LedgerDetail);
