import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import eventEmitter from '../../../helpers/eventEmitter'

class BulkPayoutButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            rows: null
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.wrapper = React.createRef();
    }

    handleClose() {
        this.setState({
            rows: this.props.rows,
            showModal: false
        })
    };
    handleShow() {
        this.setState({
            rows: this.props.rows,
            showModal: true
        })
    };

    handleDisplayPayouts() {
        let retVal = [];
        for(let i = 0; i < this.props.rows.length; i++) {
            let row = this.props.rows[i];
            if(row.original.net > 0) {
                retVal.push(<span key={'display-' + i}>{row.original.user_name}: ${(row.original.net/100).toFixed(2)}<br/></span>)
            }
        }
        return retVal;
    }

    handleBulkPayouts(rows) {
        let payoutRows = rows.filter(row => { return row.original.net > 0 })
        let payoutObjects = payoutRows.map((i) => {
            return i.original;
        })
        eventEmitter.emit('setLoading', true);
        fetch('/api/ledger/bulkPayouts', {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache',
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payoutObjects)
          })
          .then(response => response.json())
          .then((result) => {
              debugger
              console.log('got to result')
              this.handleClose();
              this.props.onChange();
              eventEmitter.emit('setLoading', false);
          }, (error) => {
              debugger
              console.error(error.message);
              eventEmitter.emit('setLoading', false);
          })
    }

    render() {
        return (
            <div ref={this.wrapper} style={{padding: '0px 5px'}}>
                <Button variant="primary" onClick={this.handleShow} disabled={this.props.rows.length <= 0}>Bulk Payout</Button>

                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payout Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to pay out the following groups? <br/>
                        {this.handleDisplayPayouts()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                        <Button variant="primary" onClick={() => {this.handleBulkPayouts(this.props.rows)}}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default BulkPayoutButton;
