import React from 'react';
import { Row, Col, Form, FormControl, ButtonGroup, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import eventEmitter from '../../../helpers/eventEmitter'

class UserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalUser: {
                name: '',
                email: '',
                role: '',
                isVerified: false
            },
            name: '',
            email: '',
            role: '',
            isVerified: false,
            password: '',
            passwordCheck: '',
            isInvalidEmail: false,
            isInvalidPassword: false,
            isMatchingPassword: true
        }
        this.changeEmail = this.changeEmail.bind(this);
        this.changeEmailVerified = this.changeEmailVerified.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeRole = this.changeRole.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changePasswordCheck = this.changePasswordCheck.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.checkValidCreate = this.checkValidCreate.bind(this);
        this.checkValidUpdate = this.checkValidUpdate.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        if (!this.props.isLoading && this.props.match.params.userId !== 'create') {
            eventEmitter.emit('setLoading', true);
            fetch(`/api/userProfiles/${this.props.match.params.userId}`)
                .then(response => response.json())
                .then((result) => {
                    let user = result[0]
                    let originalUser = {
                        name: user.user_metadata.name,
                        email: user.email,
                        role: user.app_metadata.role,
                        isVerified: user.email_verified
                    }
                    this.setState({
                        originalUser: originalUser,
                        name: user.user_metadata.name,
                        email: user.email,
                        role: user.app_metadata.role,
                        isVerified: user.email_verified
                    })
                    eventEmitter.emit('setLoading', false);
                })
        }
    }

    submitForm() {
        let url = ''
        let body = {
            email: this.state.email,
            email_verified: this.state.isVerified,
            user_metadata: {
                name: this.state.name
            },
            app_metadata: {
                role: this.state.role
            },
            connection: "Username-Password-Authentication",
        }
        if (this.props.match.params.userId === 'create') {
            url = '/api/user/create';
            body.password = this.state.password;
        } else {
            url = '/api/user/update'
            body.user_id = this.props.match.params.userId;
            if (this.state.password) {
                body.password = this.state.password;
            }
            console.log(body);
        }
        eventEmitter.emit('setLoading', true);
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then((result) => {
                debugger
                if(result.error) {
                    
                }
                eventEmitter.emit('setLoading', false);
                window.location.href = `/admin/users/${result.user_id}`;
            }, (error) => {
                debugger
                console.log(error);
            })
    }

    checkEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkPassword(password) {
        const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        return password.match(re);
    }

    resetForm() {
        this.setState({
            name: this.state.originalUser.name,
            email: this.state.originalUser.email,
            role: this.state.originalUser.role,
            isVerified: this.state.originalUser.isVerified,
            password: '',
            passwordCheck: ''
        })
    }

    changeName(event) {
        this.setState({
            name: event.target.value.trim()
        });
    }

    changeEmail(event) {
        let isValidEmail = false;
        if (this.checkEmail(event.target.value)) {
            isValidEmail = true;
        }
        this.setState({
            email: event.target.value,
            isInvalidEmail: !isValidEmail
        })
    }

    changeRole(event) {
        this.setState({
            role: event.target.value
        })
    }

    changeEmailVerified(event) {
        this.setState({
            isVerified: !this.state.isVerified
        })
    }

    changePassword(event) {
        let validPassword = false;
        let isMatchingPassword = false;
        if (this.checkPassword(event.target.value)) {
            validPassword = true;
        }
        if (event.target.value === this.state.passwordCheck) {
            isMatchingPassword = true;
        }
        this.setState({
            password: event.target.value,
            isInvalidPassword: !validPassword,
            isMatchingPassword: isMatchingPassword
        })
    }

    changePasswordCheck(event) {
        let isMatchingPassword = false;
        if (event.target.value === this.state.password || this.state.password === '') {
            isMatchingPassword = true;
        }
        this.setState({
            passwordCheck: event.target.value,
            isMatchingPassword: isMatchingPassword
        })
    }

    checkValidUpdate() {
        return !this.state.isInvalidEmail &&
            this.state.name !== '' &&
            this.state.role !== '' &&
            (!this.state.isInvalidPassword || this.state.password === '') &&
            this.state.isMatchingPassword;
    }
    checkValidCreate() {
        return !this.state.isInvalidEmail &&
            this.state.name !== '' &&
            this.state.role !== '' &&
            !this.state.isInvalidPassword &&
            this.state.isMatchingPassword &&
            this.state.password !== '';
    }

    render() {
        let validForSubmit = this.props.match.params.userId !== 'create' ? this.checkValidUpdate() : this.checkValidCreate();
        let retVal = (
            <div>
                <Row className="sub-header p-5">
                    <Col xs="12" sm="5">
                        <h1 className="text-left">User Profile</h1>
                    </Col>
                    <Col xs="12" sm="7">
                        <div className="d-flex justify-content-end align-items-center">
                            <div>

                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-md-center" >
                    <Col xs="12" md="5" >
                        <Form className="m-5">
                            <Form.Group>
                                <Form.Label className="text-left d-block">Name</Form.Label>
                                <FormControl id="name" onChange={this.changeName} value={this.state.name} />
                            </Form.Group>
                            <Form.Group >
                                <Form.Label className="text-left d-block">Email</Form.Label>
                                <FormControl id="email" onChange={this.changeEmail} value={this.state.email} isInvalid={this.state.isInvalidEmail} />
                                <Form.Control.Feedback type="invalid">
                                    Must be a valid email address
                        </Form.Control.Feedback>
                            </Form.Group>
                            {(this.props.profile && this.props.profile.app_metadata.role === 'admin') &&
                                <Form.Group>
                                    <Form.Label className="text-left d-block">Role</Form.Label>
                                    <Form.Control as="select" id="role" onChange={this.changeRole} value={this.state.role}>
                                        <option value="">Choose One...</option>
                                        <option value="creator">Creator</option>
                                        <option value="designer">Designer</option>
                                        <option value="admin">Admin</option>
                                    </Form.Control>
                                </Form.Group>
                            }
                            {(this.props.profile && this.props.profile.app_metadata.role === 'admin') &&
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check className="text-left" type="checkbox" label="Email Verified" checked={this.state.isVerified} onChange={this.changeEmailVerified} />
                                </Form.Group>
                            }

                            <hr />

                            <Form.Group >
                                <Form.Label className="text-left d-block">Password</Form.Label>
                                <FormControl id="password" onChange={this.changePassword} value={this.state.password} isInvalid={this.state.isInvalidPassword} />
                                <Form.Control.Feedback type="invalid">
                                    Must be 7 to 15 characters long and contain at least one number and one special character
                        </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group >
                                <Form.Label className="text-left d-block">Confirm Password</Form.Label>
                                <FormControl id="passwordCheck" onChange={this.changePasswordCheck} value={this.state.passwordCheck} isInvalid={!this.state.isMatchingPassword} />
                                <Form.Control.Feedback type="invalid">
                                    Your password must match
                        </Form.Control.Feedback>
                            </Form.Group>

                            <hr />

                            <div className="d-flex justify-content-starts mt-4">
                                <ButtonGroup>
                                    <Button variant="secondary" onClick={this.resetForm}>Reset</Button>
                                    <Button variant="primary" disabled={!validForSubmit} onClick={this.submitForm}>Save</Button>
                                </ButtonGroup>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        )

        return retVal;
    }
}

export default withRouter(UserDetail)
