import React from 'react';
import { useState, useMemo, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Col, Row, FormControl, Button } from 'react-bootstrap';
import moment from 'moment';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';

import CustomDatePicker from '../general/date-picker';
import { setDateSettings, setFullDateSettings, formatDateForQuery, startOfMonth } from '../../helpers/cycle';
import eventEmitter from '../../helpers/eventEmitter';
import ExportCsvButton from '../general/export-csv-button';

function TransactionsDetail(props) {
    console.log(props);

    const [shouldLoadData, setShouldLoadData] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [rows, setRows] = useState([]);
    const columns = useMemo(() => [
        {
            accessor: 'kind',
            Header: 'Kind',
            disableSortBy: true,
            className: 'text-left',
            Cell: props => <span style={{ display: 'block' }} className="label label-default">{props.value}</span>
        }, {
            accessor: 'created_at',
            Header: 'Created On',
            className: 'text-left',
        }, {
            accessor: 'description',
            Header: 'Description',
            className: 'text-left',
        }, {
            accessor: 'amount',
            Header: () => <span style={{ float: 'right' }}>Amount</span>,
            className: 'text-right',
            Cell: props => <span className="text-right">${(props.value / 100).toFixed(2)}</span>
        },
    ]
        , []);

    async function loadData(startDate, endDate) {
        let user_id = props.profile.user_id;
        if (props.match.params.userId) {
            user_id = props.match.params.userId;
        }
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/transactions/${user_id}/${props.kind}/${formatDateForQuery(startDate, false)}/${formatDateForQuery(endDate, true)}`)
        const rows = await response.json();
        eventEmitter.emit('setLoading', false);
        setRows(rows);
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                <FormControl
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search`}
                />
            </span>
        )
    }

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            rows,
            prepareRow,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: {
                pageIndex,
                pageSize,
                globalFilter
            },
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
        } = useTable({
            columns,
            data,
            initialState: {
                pageSize: 100
            }
        }, useGlobalFilter, useSortBy, usePagination)

        // Render the UI for your table
        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                    <ExportCsvButton
                        name={props.kind === 'credit' ? 'Income' : 'Expenses'}
                        tableRows={rows}
                        formatters={['excluded', '', '', 'money', 'date', 'date', 'excluded', 'excluded', 'excluded', 'date', '']}
                    />
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{ padding: '15px 0' }}>
                        <div style={{ float: 'left' }}>
                            <Button variant="default" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </Button>{' '}
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div style={{ float: 'right' }}>

                            <select
                                className="dropdown-toggle btn btn-default"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[100, 200, -1].map(pageSize => (
                                    <option key={pageSize} value={pageSize} style={{color: 'white'}}>
                                        {pageSize > 0 ? pageSize : 'All'}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function ReturnLink() {
        let retVal = null;
        if (props.match.params.userId) {
            retVal = (<Link className="btn btn-default dftba-backbutton" to={`/admin/ledger/${props.match.params.userId}`}>Back</Link>)
        }
        return retVal;
    }

    useEffect(async () => {
        if (!props.isLoading && shouldLoadData) {
            let eDate = new Date();
            eDate.setDate(eDate.getDate() + 1);
            eDate = setDateSettings(eDate, props.settings)

            let sDate = new Date();
            sDate = startOfMonth(sDate);
            sDate = setFullDateSettings(sDate, props.settings);
            await loadData(sDate, eDate, props);
            setShouldLoadData(false);
            if (!startDate || !endDate) {
                setStartDate(sDate);
                setEndDate(eDate);
            }
        }
    }, [props.isLoading]);

    let retVal = (
        <>
            <Row className="sub-header p-5">
                <Col xs="12" sm="5">
                    <h1 className="text-left">{props.kind === 'credit' ? 'Income' : 'Expenses'}</h1>
                </Col>
                <Col xs="12" sm="7">
                </Col>
            </Row>
        </>)
        ;
    if (!props.isLoading && !shouldLoadData) {
        retVal = (
            <>
                <Row className="sub-header p-5">
                    <Col xs="12" sm="5">
                        <h1 className="text-left">{props.kind === 'credit' ? 'Income' : 'Expenses'}</h1>
                    </Col>
                    <Col xs="12" sm="7">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="mr-2">
                                <CustomDatePicker onChange={loadData} cycleTime={moment(startDate).toString()} settings={props.settings} />
                            </div>
                            <div>
                                <ReturnLink />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Table columns={columns} data={rows} />
            </>
        )
    }
    return retVal;
}

export default withRouter(TransactionsDetail);