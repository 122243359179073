import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import ProductView from './Product-view';
import AddCommissionButton from './add-commission-button';
import eventEmitter from '../../../helpers/eventEmitter'

function ProductDetail(props) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [commissionRows, setCommissionRows] = useState([]);
    const [product, setProduct] = useState({});
    const [creators, setCreators] = useState([]);
    const [productVariants, setProductVariants] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [flat, setFlat] = useState(0);
    const [isInvalid, setIsInvalid] = useState(true);
    const commissionColumns = useMemo(() => [
        {
            //headerClasses: 'text-left',
            className: 'text-left',
            accessor: 'creator',
            Header: 'Creator',
        }, {
            accessor: 'variant_title',
            className: 'text-left',
            Header:'Variant Title'
        }, {
            //headerClasses: 'text-right',
            className: 'text-right',
            accessor: 'percent',
            Header: 'Percent',
            Cell: percentageFormatter
        }, {
            //headerClasses: 'text-right',
            className: 'text-right',
            accessor: 'flat',
            Header: 'Flat Rate',
                }, {
            //headerClasses: 'text-right',
            className: 'text-right',
            accessor: 'min_price',
            Header: 'Commission',
            Cell: commissionFormatter

        }, {
            //headerClasses: 'text-right',
            classes: 'text-right',
            accessor: 'id',
            Header: 'Actions',
            Cell: actionFormatter
        }
    ], [creators, productVariants])
    async function loadData(productId) {
        debugger
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/products/${productId}`);
        let result = await response.json();
        setProduct(result.product[0]);
        setCommissionRows(result.commissions);
        setCreators(result.creators);
        setProductVariants(result.variants);
        setDataLoaded(true);
        eventEmitter.emit('setLoading', false);
    }

    async function reloadComissions() {
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/product/commissions/${props.match.params.productId}`);
        let result = await response.json();
        setCommissionRows(result);
        eventEmitter.emit('setLoading', false);
    }

    function percentageFormatter(props) {
        return (<span>{props.value}%</span>)
    }

    function actionFormatter(props) {
        debugger
        return (
            <AddCommissionButton
                creators={creators}
                variants={productVariants}
                creator={props.row.original.user_id}
                variant={props.row.original.variant_id}
                percentage={props.row.original.percent}
                flatFee={props.row.original.flat}
                id={props.row.original.id}
                onChange={reloadComissions} />
        )
    }

    function commissionFormatter(props) {
        let percentage = props.row.original.percent / 100;
        let minCommission = (parseFloat(props.row.original.min_price) * percentage) + parseFloat(props.row.original.flat);
        let maxCommission = (parseFloat(props.row.original.max_price) * percentage) + parseFloat(props.row.original.flat);
        let retVal = `$${minCommission.toFixed(2)}`;
        if (maxCommission > minCommission) {
            retVal += ` - $${maxCommission.toFixed(2)}`
        }
        return (<span>{retVal}</span>)
    }

    useEffect(async () => {
        if (!props.isLoading) {
            console.log(props)
            let { productId } = props.match.params;
            loadData(productId);
        }
    }, [props.isLoading]);

    function Table({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            footerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        }, useSortBy)

        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    {/* CSV Export Here */}
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            {footerGroups.map(group => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map(column => (
                                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }

    let retVal = <> </>

    if (!props.isLoading && dataLoaded) {
        retVal = <>
            <Row className="sub-header p-5">
                <Col xs="12" sm="5">
                    <h1 className="text-left">{product.title}</h1>
                </Col>
                <Col xs="12" sm="7">
                    <div className="d-flex justify-content-end align-items-center">
                        <div className="mr-2">
                            <Link className="btn btn-default dftba-backbutton" to={`/admin/products`}>Back</Link>
                        </div>
                    </div>
                </Col>
            </Row>
            <div style={{padding: '0px 15px'}}>
                <Row>
                    <Col xs="12" md="4">
                        <h2 className="text-left">Product Details</h2>
                        <ProductView product={product} />
                    </Col>
                    <Col xs="12" md="8">
                        <h2 className="text-left">Commission Splits</h2>
                        <Table columns={commissionColumns} data={commissionRows} />
                        <AddCommissionButton creators={creators} variants={productVariants} productId={props.match.params.productId} onChange={reloadComissions} />
                    </Col>
                </Row>

            </div>
        </>
    }
    return retVal;
}

// class ProductDetail extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             bordered: false,
//             striped: false,
//             hover: true,
//             condensed: true,
//             wrapperClasses: 'table-responsive',
//             dataLoaded: false,
//             product: {},
//             commissions: [],
//             commissionsColumns: [
//                 {
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     dataField: 'creator',
//                     text: 'Creator',
//                 }, {
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     dataField: 'percent',
//                     text: 'Percent',
//                     sort: true,
//                     formatter: this.percentageFormatter
//                 }, {
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     dataField: 'flat',
//                     text: 'Flat Rate',
//                     sort: true
//                 }, {
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     dataField: 'min_price',
//                     text: 'Commission',
//                     sort: true,
//                     formatter: this.commissionFormatter

//                 }, {
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     dataField: 'id',
//                     text: 'Actions',
//                     formatter: this.actionFormatter,
//                     productDetail: this
//                 }
//             ],
//             creators: [],
//             selectedCreator: {},
//             percentage: 0,
//             flatFee: 0,
//             isInvalid: true,
//             isLoading: true
//         }
//         this.reloadComissions = this.reloadComissions.bind(this);
//     }

//     componentDidMount() {
//         if (!this.props.isLoading) {
//             console.log(this.props)
//             let { productId } = this.props.match.params;
//             this.loadProductAndcommissions(productId);
//         }
//     }

//     loadProductAndcommissions(productId) {
//         eventEmitter.emit('setLoading', true);
//         fetch(`/api/products/${productId}`)
//             .then(response => response.json())
//             .then((result) => {
//                 this.setState({
//                     product: result.product[0],
//                     commissions: result.commissions,
//                     creators: result.creators,
//                     isLoading: false
//                 })
//                 eventEmitter.emit('setLoading', false);
//             })
//     }

//     reloadComissions() {
//         eventEmitter.emit('setLoading', true);
//         fetch(`/api/product/commissions/${this.props.match.params.productId}`)
//             .then(response => response.json())
//             .then((result) => {
//                 this.setState({
//                     commissions: result
//                 })
//                 eventEmitter.emit('setLoading', false);
//             })
//     }

//     percentageFormatter(cell, row) {
//         return (<span>{cell}%</span>)
//     }

//     actionFormatter(cell, row) {
//         return (

//             <AddCommissionButton
//                 creators={this.productDetail.state.creators}
//                 creator={row.user_id}
//                 percentage={row.percent}
//                 flatFee={row.flat}
//                 id={row.id}
//                 onChange={this.productDetail.reloadComissions} />
//         )
//     }

//     commissionFormatter(cell, row) {
//         let percentage = row.percent / 100;
//         let minCommission = (parseFloat(row.min_price) * percentage) + parseFloat(row.flat);
//         let maxCommission = (parseFloat(row.max_price) * percentage) + parseFloat(row.flat);
//         let retVal = `$${minCommission.toFixed(2)}`;
//         if (maxCommission > minCommission) {
//             retVal += ` - $${maxCommission.toFixed(2)}`
//         }
//         return (<span>{retVal}</span>)
//     }


//     render() {
//         let retVal = (<div> </div>);
//         if (!this.props.isLoading && !this.state.isLoading) {
//             retVal = (
//                 <div className="m-4">
//                     <Row className="sub-header p-5">
//                         <Col xs="12" sm="5">
//                             <h1 className="text-left">{this.state.product.title}</h1>
//                         </Col>
//                         <Col xs="12" sm="7">
//                             <div className="d-flex justify-content-end align-items-center">
//                                 <div className="mr-2">
//                                     <Link className="btn btn-default dftba-backbutton" to={`/admin/products`}>Back</Link>
//                                 </div>
//                                 <div>
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                     <div>
//                         <Row>
//                             <Col xs="12" md="4">
//                                 <h2 className="text-left">Product Details</h2>
//                                 <ProductView product={this.state.product} />
//                             </Col>
//                             <Col xs="12" md="8">
//                                 <h2 className="text-left">Commission Splits</h2>
//                                 <BootstrapTable
//                                     bootstrap4
//                                     keyField="title"
//                                     bordered={this.state.bordered}
//                                     wrapperClasses={this.state.wrapperClasses}
//                                     data={this.state.commissions}
//                                     columns={this.state.commissionsColumns}
//                                     noDataIndication="No commissions for this product" />

//                                 <h2 className="text-left">Add commission Split</h2>
//                                 <AddCommissionButton creators={this.state.creators} productId={this.props.match.params.productId} onChange={this.reloadComissions} />
//                             </Col>
//                         </Row>

//                     </div>
//                 </div>

//             )
//         }
//         return retVal;
//     }
// }

export default withRouter(ProductDetail);