import Moment from 'moment'
import { extendMoment } from 'moment-range';
 
Moment.fn.toJSON = function() { return this.format(); }
const moment = extendMoment(Moment);

//const detailedFormat = 'dddd, MMMM Do YYYY, h:mm:ss a'

const defaults = {
  cycleTime: moment()
}

export function localizeTime(value) {
  return moment(value).minutes()
}

export function getCurrentCycle() {
//   let o = {...defaults, ...opts}
  // let originalOffset = moment().utcOffset();
  // let mtOffset = moment().isDST() ? '-06:00' : '-07:00';
  // let endOfDayMountainTime = moment().utc().add(originalOffset, 'minutes').utcOffset(mtOffset);

  // let cycleStart = moment([endOfDayMountainTime.year(), endOfDayMountainTime.month()]).utc().add(originalOffset, 'minutes').utcOffset(mtOffset);
  // let cycleEnd = cycleStart.clone().set({'date': cycleStart.daysInMonth()}).endOf('day').utc().add(originalOffset, 'minutes').utcOffset(mtOffset);

  let nowMountainTime = moment();
  let cycleStartDate = moment([nowMountainTime.year(), nowMountainTime.month()]); // First of the current month
  let cycleEndDate = cycleStartDate.clone().set({ 'date': cycleStartDate.daysInMonth() }).endOf('day'); // 11:59:59:999 PM on last day of the current month
  // cycleStartDate = cycleStartDate.utc().utcOffset(mtOffset).endOf('day').add(1, 'millisecond');
  // cycleEndDate = cycleEndDate.utc().utcOffset(mtOffset).endOf('day');
  console.log('Cycle Start: ' + cycleStartDate.toJSON());
  console.log('Cycle End: ' + cycleEndDate.toJSON());

  let cycle = moment.range(cycleStartDate, cycleEndDate);

  if (!cycle.contains(nowMountainTime, { excludeStart: true })) {
	let cycleClone = cycle.clone();
	cycle.start = cycleClone.start.add(1, 'millisecond');
	cycle.end = cycleClone.start.clone().set({ 'date': cycleClone.start.daysInMonth() }).endOf('day');
  }

//   let today = moment()
//   let cycleTime = moment(Date.parse(o.cycleTime)).utc().utcOffset(offset)
//   let currentMonthEndDate = moment(cycleTime).utcOffset(offset)
//   let currentMonthStartDate = moment(cycleTime).subtract(1, 'month').add(1, 'second')//.utcOffset(o.offset)
//   let cycle = moment.range(currentMonthStartDate, currentMonthEndDate)

//   if (!cycle.contains(today, true)) {
//     cycle.start.add(1, 'month')
//     cycle.end.add(1, 'month')
//   }

  return cycle
}

export function getLastNCycles(n, opts = {}) {
  let current = getCurrentCycle()
  let cycles = [current]
  while (cycles.length < n) {
    let curr = cycles[cycles.length - 1]
    let end = moment(curr.start).subtract(1, 'millisecond')
    let start = moment(end).set({ 'date': 1 }).startOf('day')
    let cycle = moment.range(start, end)
    cycles.push(cycle)
  }
  return cycles
}

export function setDateSettings(date, settings) {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
}

export function setFullDateSettings(date, settings) {
  date.setDate(1);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
}

export function formatDateForQuery(date, endOfDay) {
  let retVal = null;
  if(date) {
	let mDate = moment(date);
	let offset = mDate.isDST() ? '-06:00' : '-07:00';
  let originalOffset = mDate.utcOffset() + (mDate.isDST() ? 360: 420);
  let mDateMountainTime = mDate;
  if(endOfDay) {
	  mDateMountainTime = mDate.utc().add(originalOffset, 'minutes').utcOffset(offset).endOf('day');
  } else {
    mDateMountainTime = mDate.utc().add(originalOffset, 'minutes').utcOffset(offset).startOf('day'); 
  }
  console.log('Mountain Time', mDateMountainTime.toJSON());
	retVal = mDateMountainTime.toJSON().replaceAll('/','-')
    // retVal = date.toLocaleString("en-US", { timeZone: 'UTC' }).replaceAll('/','-')
  }
  return retVal;
}


export function startOfMonth(date)
{
   
 return new Date(date.getFullYear(), date.getMonth(), 1);

}
