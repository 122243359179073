import { useAsyncDebounce, useTable, useGlobalFilter, useSortBy, usePagination, useRowSelect } from 'react-table';
import { Row, Col, Button, FormControl } from 'react-bootstrap';
import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment';

import CustomDatePicker from '../../general/date-picker';
import ActionButton from './actions-modal';
import BulkPayoutButton from './bulk-payout';
import AddTransactionButton from '../../general/add-transaction-button';
import { setDateSettings, setFullDateSettings, formatDateForQuery, startOfMonth } from '../../../helpers/cycle';
import eventEmitter from '../../../helpers/eventEmitter'
import ExportCsvButton from '../../general/export-csv-button';

function LedgerList(props) {

    function formatMoney(props) {
        let retVal = <><span className="text-right" style={{ color: props.value > 0 ? 'chartreuse' : 'red' }}>${(props.value / 100).toFixed(2)}</span></>
        if (Number(props.value) === 0) {
            retVal = <><span className="text-right">${(props.value / 100).toFixed(2)}</span></>
        }
        return retVal;
    }

    const [dataLoaded, setDataLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const columns = useMemo(() => [
        {
            accessor: 'user_name',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Creator',
            Cell: props => <><img src={props.row.original.picture} alt={props.row.original.user_name} style={{ maxHeight: '20px', marginTop: '-2px', borderRadius: '50%' }} /> {props.value}</>,
            //csvText: 'Creator Name'
        }, {
            accessor: 'startingbalance',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Starting Balance</span>,
            Cell: formatMoney,
        }, {
            accessor: 'expenses',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Gross Expenses</span>,
            Cell: formatMoney,
            //csvFormatter: (cell, row, rowIndex) => `$ ${(cell / 100).toFixed(2)}`
        }, {
            accessor: 'profit',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Gross Income</span>,
            Cell: formatMoney,
            //csvFormatter: (cell, row, rowIndex) => `$ ${(cell / 100).toFixed(2)}`
        }, {
            accessor: 'net',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Net Balance</span>,
            Cell: formatMoney,
            //csvFormatter: (cell, row, rowIndex) => `$ ${(cell / 100).toFixed(2)}`,
            //csvText: 'Net Payout'
        }, {
            accessor: 'user_id',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Actions</span>,
            //isDummyField: true,
            Cell: props => {
                return <><ActionButton row={props.row.original} onChange={loadData} startDate={startDate} endDate={endDate} /></>
            },
            disableSortBy: true
            //ledger: this,
            //csvExport: false
        }, {
            accessor: 'startdate',
            Header: 'Start Date',
            hidden: true,
            //csvFormatter: (cell, row, rowIndex) => `${new Date(cell).toLocaleString()}`,
            //csvText: 'Cycle Start'
        }, {
            accessor: 'enddate',
            Header: 'End Date',
            hidden: true,
            //csvFormatter: (cell, row, rowIndex) => `${new Date(cell).toLocaleString()}`,
            //csvText: 'Cycle End'
        }
    ], [startDate, endDate])

    async function loadData(stDate = null, enDate = null) {
        const sDate = stDate ? stDate : startDate;
        const eDate = enDate ? enDate : endDate;
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/ledgerSummaries/${formatDateForQuery(sDate, false)}/${formatDateForQuery(eDate, true)}`)
        const rows = await response.json();
        eventEmitter.emit('setLoading', false);
        setRows(rows);
        setDataLoaded(true);
        setStartDate(startDate);
        setEndDate(endDate);
    }

    useEffect(async () => {
        if (!props.isLoading && !dataLoaded) {
            let eDate = new Date();
            eDate.setDate(eDate.getDate() + 1);
            eDate = setDateSettings(eDate)

            let sDate = new Date();
            sDate = startOfMonth(sDate);
            sDate = setDateSettings(sDate);
            await loadData(sDate, eDate);
            setStartDate(sDate);
            setEndDate(eDate);
        }
    }, [props.isLoading]);

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                <FormControl
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search`}
                />
            </span>
        )
    }

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            )
        }
    )

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: {
                pageIndex,
                pageSize,
                globalFilter,
                selectedRowIds
            },
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            selectedFlatRows
        } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['startdate', 'enddate'],
                pageSize: 100
            }
        }, 
        useGlobalFilter, 
        useSortBy, 
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div style={{textAlign: 'center'}}>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ])
          })

        // Render the UI for your table
        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                    <BulkPayoutButton rows={selectedFlatRows} onChange={loadData} />
                    <ExportCsvButton 
                        name="LedgerDetail"
                        tableRows={selectedFlatRows}
                        formatters={["excluded","excluded","","","money","money","money","money","date","date"]}/>
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{padding: '15px 0'}}>
                        <div style={{ float: 'left' }}>
                            <Button variant="default" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </Button>{' '}
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div style={{ float: 'right' }}>

                            <select
                                className="dropdown-toggle btn btn-default"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[100, 200, -1].map(pageSize => (
                                    <option key={pageSize} value={pageSize} style={{color: 'white'}}>
                                        {pageSize > 0 ? pageSize : 'All'}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let retVal = <>
        <Row className="sub-header p-5">
            <Col xs="12" sm="5">
                <h1 className="text-left">Ledger</h1>
            </Col>
        </Row>
    </>
    if (!props.isLoading && dataLoaded) {
        retVal = <>
            <Row className="sub-header p-5">
                <Col xs="12" sm="5">
                    <h1 className="text-left">Ledger</h1>
                </Col>
                <Col xs="12" sm="7">
                    <div className="d-flex justify-content-end align-items-center">
                        <div className="mr-2">
                            <CustomDatePicker onChange={loadData} cycleTime={moment(startDate).toString()} settings={props.settings} />
                        </div>
                        <div className="mr-2">
                            <AddTransactionButton kind="Expense" onChange={loadData} />
                        </div>
                        <div>
                            <AddTransactionButton kind="Credit" onChange={loadData} />
                        </div>
                    </div>
                </Col>
            </Row>

            <Table columns={columns} data={rows} />
        </>
    }
    return retVal;
}

// *** OLD COMPONENT VERSION, DELETE LATER ***
// class LedgerList extends React.Component {
//     constructor(props) {
//         super(props);
//         let eDate = new Date();
//         eDate.setDate(eDate.getDate() + 1);
//         eDate = setDateSettings(eDate, props.settings)

//         let sDate = new Date();
//         if(sDate.getDate() < props.settings.day) {
//             sDate.setMonth(sDate.getMonth() - 1);
//         }
//         sDate = setFullDateSettings(sDate, props.settings);

//         this.wrapper = React.createRef();

//         this.state = {
//             bordered: false,
//             striped: false,
//             hover: true,
//             condensed: true,
//             wrapperClasses: 'table-responsive',
//             dataLoaded: false,
//             rows: [],
//             paginationOptions: {
//                 sizePerPage: 100,
//                 sizePerPageList: [
//                     { text: '25', value: 25 },
//                     { text: '50', value: 50 },
//                     { text: '100', value: 100 },
//                     { text: '500', value: 500 }]
//             },
//             columns: [
//                 {
//                     dataField: 'user_name',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Creator',
//                     sort: true,
//                     formatter: this.profileFormatter,
//                     csvText: 'Creator Name'
//                 }, {
//                     dataField: 'startingbalance',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Starting Balance',
//                     sort: true,
//                     formatter: this.moneyFormatter,
//                     csvFormatter: (cell, row, rowIndex) => `$ ${(cell / 100).toFixed(2)}`,
//                 }, {
//                     dataField: 'expenses',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Gross Expenses',
//                     sort: true,
//                     formatter: this.moneyFormatter,
//                     csvFormatter: (cell, row, rowIndex) => `$ ${(cell / 100).toFixed(2)}`
//                 }, {
//                     dataField: 'profit',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Gross Income',
//                     sort: true,
//                     formatter: this.moneyFormatter,
//                     csvFormatter: (cell, row, rowIndex) => `$ ${(cell / 100).toFixed(2)}`
//                 }, {
//                     dataField: 'net',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Net Balance',
//                     sort: true,
//                     formatter: this.moneyFormatter,
//                     csvFormatter: (cell, row, rowIndex) => `$ ${(cell / 100).toFixed(2)}`,
//                     csvText: 'Net Payout'
//                 }, {
//                     dataField: 'user_id',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Actions',
//                     isDummyField: true,
//                     formatter: this.actionFormatter,
//                     ledger: this,
//                     csvExport: false
//                 }, {
//                     dataField: 'startdate',
//                     text: 'Start Date',
//                     hidden: true,
//                     csvFormatter: (cell, row, rowIndex) => `${new Date(cell).toLocaleString()}`,
//                     csvText: 'Cycle Start'
//                 }, {
//                     dataField: 'enddate',
//                     text: 'End Date',
//                     hidden: true,
//                     csvFormatter: (cell, row, rowIndex) => `${new Date(cell).toLocaleString()}`,
//                     csvText: 'Cycle End'
//                 }
//             ],
//             selectedRows: [],
//             endDate: eDate,
//             startDate: sDate,
//             defaultSorted: [{
//                 dataField: 'user_name',
//                 order: 'asc'
//             }]
//         }


//         this.loadSummaries = this.loadSummaries.bind(this);
//         this.reLoadSummaries = this.reLoadSummaries.bind(this);
//         this.handleOnSelectRow = this.handleOnSelectRow.bind(this);
//         this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
//         this.actionFormatter = this.actionFormatter.bind(this);
//     }

//     wrapper = createRef();

//     componentDidMount() {
//         if (!this.props.isLoading) {
//             this.reLoadSummaries();
//         }
//     }

//     reLoadSummaries() {
//         eventEmitter.emit('setLoading', true);
//         fetch(`/api/ledgerSummaries/${formatDateForQuery(this.state.startDate)}/${formatDateForQuery(this.state.endDate)}`)
//             .then(response => response.json())
//             .then((result) => {
//                 result.map((i) => {
//                     i.user_name = i.user_name.trim()
//                     i.net = Number(i.net);
//                     i.startingbalance = Number(i.startingbalance);
//                     i.expenses = Number(i.expenses);
//                     i.profit = Number(i.profit);

//                     return i;
//                 })
//                 this.setState({
//                     rows: result,
//                     dataLoaded: true
//                 })
//                 eventEmitter.emit('setLoading', false);
//             })
//     }

//     loadSummaries(startDate, endDate) {
//         eventEmitter.emit('setLoading', true);
//         fetch(`/api/ledgerSummaries/${formatDateForQuery(startDate)}/${formatDateForQuery(endDate)}`)
//             .then(response => response.json())
//             .then((result) => {
//                 this.setState({
//                     rows: result,
//                     dataLoaded: true,
//                     startDate: startDate,
//                     endDate: endDate
//                 })
//                 eventEmitter.emit('setLoading', false);
//             })
//     }

//     handleOnSelectRow(row, isSelect, rowIndex, e) {
//         if (isSelect) {
//             this.setState(() => ({
//                 selectedRows: [...this.state.selectedRows, row]
//             }));
//         } else {
//             this.setState(() => ({
//                 selectedRows: this.state.selectedRows.filter(x => x.user_id !== row.user_id)
//             }));
//         }
//     }

//     handleOnSelectAll(isSelect, rows) {
//         if (isSelect) {
//             this.setState(() => ({
//                 selectedRows: rows
//             }));
//         } else {
//             this.setState(() => ({
//                 selectedRows: []
//             }));
//         }
//     }


//     moneyFormatter(cell, row) {
//         let color = (Math.sign(cell) === -1 && !row.payout) ? 'red' : 'green';
//         color = Math.sign(cell) === 0 ? 'white' : color;
//         let retVal = (
//             <div style={{ color: color }}>
//                 { (cell / 100).toFixed(2)}
//             </div>
//         );
//         return retVal;
//     }

//     profileFormatter(cell, row) {
//         let retVal = (
//             <div>
//                 <img src={row.picture} alt={row.user_name} style={{ maxHeight: '20px', marginTop: '-2px', borderRadius: '50%' }} /> {cell}
//             </div>)
//         return retVal;
//     }

//     actionFormatter(cell, row) {
//         let retVal = (
//             <div>
//                 <ActionButton row={row} ledger={this.ledger} />
//             </div>
//         )
//         return retVal;
//     }

//     render() {
//         const { SearchBar, ClearSearchButton } = Search;
//         const { ExportCSVButton } = CSVExport;

//         const selectRow = {
//             mode: 'checkbox',
//             clickToSelect: true,
//             onSelect: this.handleOnSelectRow,
//             onSelectAll: this.handleOnSelectAll
//         }

//         const selectedRows = this.state.selectedRows.length <= 0;

//         let retVal = (<div> </div>)

//         let payPeriodDate = new Date();

//         payPeriodDate = setFullDateSettings(payPeriodDate, this.props.settings);

//         if (this.state.dataLoaded && !this.props.isLoading) {
//             retVal = (
//                 <div ref={this.wrapper}>

//                     <Row className="sub-header p-5">
//                         <Col xs="12" sm="5">
//                             <h1 className="text-left">Ledger</h1>
//                         </Col>
//                         <Col xs="12" sm="7">
//                             <div className="d-flex justify-content-end align-items-center">
//                                 <div className="mr-2">
//                                     <CustomDatePicker onChange={this.loadSummaries} cycleTime={moment(payPeriodDate).toString()} settings={this.props.settings} />
//                                 </div>
//                                 <div className="mr-2">
//                                     <AddTransactionButton kind="Expense" onChange={this.reLoadSummaries} />
//                                 </div>
//                                 <div>
//                                     <AddTransactionButton kind="Credit" onChange={this.reLoadSummaries} />
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                     <ToolkitProvider
//                         bootstrap4
//                         keyField="user_id"
//                         data={this.state.rows}
//                         columns={this.state.columns}
//                         search
//                         exportCSV={{
//                             fileName: `dftba-commission-export-${new Date().toLocaleDateString()}.csv`,
//                             onlyExportSelection: true
//                         }}
//                     >
//                         {
//                             props => (
//                                 <div className="data-grid m-4">
//                                     <div className="data-grid-header d-flex justify-content-start">

//                                         <div className="d-flex justify-content-start mr-2">
//                                             <SearchBar {...props.searchProps} />
//                                         </div>
//                                         <div className="d-flex justify-content-start">
//                                             <div><ClearSearchButton {...props.searchProps} className="mr-2" /></div>
//                                             <div className="mr-2"><ExportCSVButton {...props.csvProps} disabled={selectedRows}>Export CSV</ExportCSVButton></div>
//                                             <div><BulkPayoutButton rows={this.state.selectedRows} onChange={this.reLoadSummaries} /></div>
//                                         </div>

//                                     </div>
//                                     <BootstrapTable
//                                         {...props.baseProps}
//                                         bordered={this.state.bordered}
//                                         striped={this.state.striped}
//                                         hover={this.state.hover}
//                                         condensed={this.state.condensed}
//                                         wrapperClasses={this.state.wrapperClasses}
//                                         selectRow={selectRow}
//                                         defaultSorted={this.state.defaultSorted}
//                                         pagination={paginationFactory(this.state.paginationOptions)} 
//                                         noDataIndication="No Creators/Designers to display"/>
//                                 </div>
//                             )
//                         }
//                     </ToolkitProvider>
//                 </div>
//             )
//         }
//         return retVal
//     }
// }

export default LedgerList;
