import React from 'react';

import { Col, Form, FormControl, InputGroup, ButtonGroup, Button, Modal } from 'react-bootstrap';
import eventEmitter from '../../../helpers/eventEmitter'

class AddCommissionButton extends React.Component {
    constructor(props) {
        debugger
        super(props);
        this.state = {
            originalCreator: props.creator ? props.creator : '-1',
            originalPercentage: props.percentage ? props.percentage : 0,
            originalFlatFee: props.flatFee ? props.flatFee : 0,
            originalVariant: props.variant ? props.variant : 'null',
            creator: props.creator ? props.creator : '-1',
            variant: props.variant ? new Number(props.variant) : 'null',
            percentage: props.percentage ? props.percentage : 0,
            flatFee: props.flatFee ? props.flatFee : 0,
            creators: props.creators,
            variants: props.variants,
            isInvalid: false,
            showModal: false,
        }
        this.handleCreators = this.handleCreators.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.changePercentage = this.changePercentage.bind(this);
        this.changeFlatFee = this.changeFlatFee.bind(this);
        this.changeVariant = this.changeVariant.bind(this);
    }

    changeUser(event) {
        this.setState({
            creator: event.target.value
        })
    }

    changePercentage(event) {
        if (isNaN(event.target.value) || event.target.value > 100) {
            this.setState({
                isInvalid: true,
                percentage: event.target.value
            })
        } else {
            this.setState({
                percentage: event.target.value,
                isInvalid: false
            })
        }
    }

    changeFlatFee(event) {
        if (isNaN(event.target.value)) {
            this.setState({
                flatFee: 0,
                isInvalid: true
            })
        } else {
            this.setState({
                flatFee: event.target.value,
                isInvalid: false
            })
        }
    }

    changeVariant(event) {
        this.setState({
            variant: event.target.value
        })
    }

    handleCreators() {
        let retVal = [];
        retVal.push((<option key={'option-'} value="-1">Choose one...</option>))
        for (let i = 0; i < this.state.creators.length; i++) {
            let creator = this.state.creators[i];
            retVal.push((<option key={'option-' + i} style={{ color: 'black' }} value={creator.user_id}>{creator.user_name}</option>))
        }
        return retVal;
    }

    handleVariants() {
        debugger
        let retVal = [];
        retVal.push((<option key={'variant-'} value={'null'}>All Variants</option>));
        for(let i = 0; i < this.state.variants.length; i++) {
            let variant = this.state.variants[i];
            retVal.push((<option key={'variant-' + i} style={{color: 'black'}} value={variant.id}>{variant.title}</option>))
        }
        return retVal;
    }

    handleUpdate() {
        eventEmitter.emit('setLoading', true);
        fetch(`/api/products/commission/update/${this.props.id}/${this.state.percentage ? this.state.percentage : 0}/${this.state.flatFee ? this.state.flatFee : 0}/${this.state.creator}/${this.state.variant}`)
            .then(response => response.json())
            .then((results) => {
                this.setState({
                    creator: this.state.creator ,
                    variant: Number(this.state.variant),
                    percentage: this.state.percentage ,
                    flatFee: this.state.flatFee,
                })
                this.handleClose()
                this.props.onChange();
                eventEmitter.emit('setLoading', false);
            })
    }
    handleInsert() {
        eventEmitter.emit('setLoading', true);
        fetch(`/api/products/commission/insert/${this.state.creator}/${this.props.productId}/${this.state.percentage ? this.state.percentage : 0}/${this.state.flatFee ? this.state.flatFee : 0}/${this.state.variant}`)
            .then(response => response.json())
            .then((results) => {
                this.setState({
                    creator: '-1',
                    variant: 'null',
                    percentage: 0,
                    flatFee: 0,
                })
                this.props.onChange();
                eventEmitter.emit('setLoading', false);
            })
    }
    handleDelete() {
        eventEmitter.emit('setLoading', true);
        fetch(`/api/product/commissions/delete/${this.props.id}`)
            .then(response => response.json())
            .then((results) => {
                this.handleClose()
                this.props.onChange();
                eventEmitter.emit('setLoading', false);
            })
    }

    handleSubmit() {
        if (this.props.creator) {
            this.handleUpdate()
        } else {
            this.handleInsert()
        }
    }

    handleReset() {
        this.setState({
            creator: this.state.originalCreator,
            percentage: this.state.originalPercentage,
            flatFee: this.state.originalFlatFee
        })
    }

    handleClose() {
        this.setState({
            creator: this.state.originalCreator,
            percentage: this.state.originalPercentage,
            flatFee: this.state.originalFlatFee,
            showModal: false
        })
    };
    handleShow() {
        this.setState({
            showModal: true
        })
    };

    checkConfirmButton() {
        return (this.state.percentage <= 0 && this.state.flatFee <= 0) || (this.state.isInvalid || this.state.creator === null || this.state.creator === "-1");
    }

    renderForm() {
        return (
            <div>
                <Form>
                    <Form.Group>
                        <Form.Label className="d-block text-left">Creator</Form.Label>
                        <Form.Control as="select" id="creator" onChange={this.changeUser} value={this.state.creator}>
                            {this.handleCreators()}
                        </Form.Control>
                        <Form.Label className="d-block text-left">Variants</Form.Label>
                        <Form.Control as="select" id="variant" onChange={this.changeVariant} value={this.state.variant}>
                            {this.handleVariants()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Row>
                        <Col>
                            <Form.Label className="d-block text-left">Commission Percentage</Form.Label>
                            <InputGroup>
                                <FormControl aria-describedby="inputGroupPrepend" id="percentage" placeholder="0" onChange={this.changePercentage} isInvalid={this.state.isInvalid} value={this.state.percentage} />
                                <span className="input-group-addon">%</span>
                                <Form.Control.Feedback type="invalid">
                                    Percentage must be 100% or lower
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label className="d-block text-left">Commission Flat Fee</Form.Label>  
                            <InputGroup>
                                <span className="input-group-addon">$</span>
                                <FormControl id="flatFee" placeholder="0.00" onChange={this.changeFlatFee} isInvalid={this.state.isInvalid} value={this.state.flatFee} />
                            </InputGroup>
                        </Col>
                    </Form.Row>
                </Form>
            </div >
        )
    }

    render() {

        let isDisabled = this.checkConfirmButton();

        let retVal = (
            <div>
                {this.renderForm()}
                <div className="d-flex justify-content-end mt-4">
                    <ButtonGroup> 
                        <Button variant="default" onClick={this.handleReset}>Reset</Button>
                        <Button variant="primary" onClick={this.handleSubmit} disabled={isDisabled}>Confirm</Button>
                    </ButtonGroup>
                </div>
            </div>
        )

        if (this.props.creator) {
            retVal = (
                <div>
                    <ButtonGroup>
                        <Button variant="default" onClick={this.handleShow}>Edit</Button>
                        <Button variant="primary" onClick={this.handleDelete}>Remove</Button>
                    </ButtonGroup>
                    <Modal animation={false} show={this.state.showModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Commission #{this.props.id} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.renderForm()}
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-end mt-4">
                            <Button variant="default" onClick={this.handleDelete}>Delete</Button>
                            <ButtonGroup>
                                <Button variant="default" onClick={this.handleClose}>Close</Button>
                                <Button variant="primary" onClick={this.handleSubmit} disabled={isDisabled}>Confirm</Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        }

        return retVal
    }
}

export default AddCommissionButton