import { Button } from 'react-bootstrap';
function ExportCsvButton(props) {
    /*
        props:
            tableRows: these are rows from the table directly, you want row objects as designed by the react-table
            formatters: list of formatters you want to apply to the data, leave blank if no formatter, enter excluded to exclude that column from the csv
            name: name for the table
    */
    function format(formatter, value) {
        debugger
        let retVal = value;
        switch(formatter) {
            case 'money':
                retVal = formatMoney(value);
                break;
            case 'date':
                retVal = formatDate(value);
                break;
            case 'percent':
                retVal = formatPercentage(value);
                break;
        }
        return retVal;
    }

    function formatPercentage(value) {
        return `${value}%`
    }
    
    function formatMoney(value) {
        return  `$${(value / 100).toFixed(2)}`
    }

    function formatDate(value) {
        let date = new Date(value).toLocaleString().replace(',','');
        return date
    }

    function exportCsv() {
        debugger
        var data = [];

        let headerRow = Object.keys(props.tableRows[0].original);
        for (let j = props.formatters.length - 1; j > -1; j--) {
            if(props.formatters[j]) {
                if(props.formatters[j] === 'excluded') {
                    headerRow.splice(j, 1);
                }
            }
        }
        data.push(headerRow);
        for (let i = 0; i < props.tableRows.length; i++) {
            let objectArray = Object.values(props.tableRows[i].original);

            for (let j = props.formatters.length - 1; j > -1; j--) {
                if(props.formatters[j]) {
                    if(props.formatters[j] === 'excluded') {
                        objectArray.splice(j, 1);
                    } else {
                        objectArray[j] = format(props.formatters[j], objectArray[j]);
                    }
                } else {
                    objectArray[j] = "\"" + objectArray[j] + "\"";
                }
            }
            data.push(objectArray);
        }
        let csvContent = "data:text/csv;charset=utf-8,"
            + data.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${props.name}-data-${new Date().toLocaleString()}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    return <>
        <Button variant="default" disabled={props.tableRows.length <= 0} onClick={exportCsv}>Export CSV</Button>
    </>
}

export default ExportCsvButton;