import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Button, ButtonGroup, Modal, FormControl } from 'react-bootstrap';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { Link } from 'react-router-dom';
import eventEmitter from '../../../helpers/eventEmitter'

function UserList(props) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [showModal, setShowModal] = useState(false);
    const columns = useMemo(() => [
        {
            accessor: 'name',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Name'
        }, {
            accessor: 'email',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Email'
        }, {
            accessor: 'email_verified',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Activated',
            Cell: activatedFormatter
        }, {
            accessor: 'role',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Role',
        }, {
            accessor: 'user_id',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Actions</span>,
            Cell: actionFormatter,
        }
    ], [])

    function handleClose() {
        setShowModal(false);
    };

    function handleShow(user) {
        setSelectedUser(user);
        setShowModal(true);
    };

    function handleDelete() {
        eventEmitter.emit('setLoading', true);
        fetch(`/api/user/delete/${selectedUser.user_id}`)
            .then(response => response.json())
            .then((result) => {
                handleClose();
                loadData();
                eventEmitter.emit('setLoading', false);
            })
    }

    function activatedFormatter(props) {
        let retVal = null;
        if (props.value) {
            retVal = (<span>Yes</span>)
        } else {
            retVal = (<span>No</span>)
        }
        return retVal;
    }

    function actionFormatter(props) {
        return (
            <ButtonGroup>
                <Link className="btn btn-secondary" to={`/admin/users/${props.row.original.user_id}`}>Edit</Link>
                <Button variant="primary" onClick={() => { handleShow(props.row.original) }}>Delete</Button>
            </ButtonGroup>
        )
    }

    async function loadData() {
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/userProfiles`);
        const rows = await response.json();
        eventEmitter.emit('setLoading', false);
        setRows(rows);
        setDataLoaded(true);
    }

    useEffect(async () => {
        if (!props.isLoading && !dataLoaded) {
            debugger
            await loadData();
        }
    }, [props.isLoading]);

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                <FormControl
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search`}
                />
            </span>
        )
    }

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: {
                pageIndex,
                pageSize,
                globalFilter
            },
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
        } = useTable({
            columns,
            data,
            initialState: {
                pageSize: 100
            }
        }, useGlobalFilter, useSortBy, usePagination)

        // Render the UI for your table
        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{padding: '15px 0'}}>
                        <div style={{ float: 'left' }}>
                            <Button variant="default" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </Button>{' '}
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div style={{ float: 'right' }}>
                            <select
                                className="dropdown-toggle btn btn-default"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[100, 200, -1].map(pageSize => (
                                    <option key={pageSize} value={pageSize} style={{color: 'white'}}>
                                        {pageSize > 0 ? pageSize : 'All'}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let retVal = <></>
    if (!props.isLoading && dataLoaded) {
        retVal = <>
            <Row className="sub-header p-5">
                <Col xs="12" sm="5">
                    <h1 className="text-left">Users</h1>
                </Col>
                <Col xs="12" sm="7">
                    <div className="d-flex justify-content-end align-items-center">
                        <div>
                            <Link className="btn btn-default" to="/admin/users/create">Create User</Link>
                        </div>
                    </div>
                </Col>
            </Row>

            <Table columns={columns} data={rows} />

            <Modal animation={false} show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete the user {selectedUser.name}?</Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="primary" onClick={handleDelete}>Delete</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    }
    return retVal;
}

// class UserList extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             bordered: false,
//             striped: false,
//             hover: true,
//             condensed: true,
//             wrapperClasses: 'table-responsive',
//             dataLoaded: false,
//             selectedUser: {},
//             userList: [],
//             paginationOptions: {
//                 sizePerPage: 100,
//                 sizePerPageList: [
//                     { text: '25', value: 25 },
//                     { text: '50', value: 50 },
//                     { text: '100', value: 100 },
//                     { text: '500', value: 500 }]
//             },
//             userColumns: [
//                 {
//                     dataField: 'name',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Name',
//                     sort: true
//                 }, {
//                     dataField: 'email',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Email',
//                     sort: true
//                 }, {
//                     dataField: 'email_verified',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Activated',
//                     sort: true,
//                     formatter: this.activatedFormatter
//                 }, {
//                     dataField: 'role',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Role',
//                     sort: true
//                 }, {
//                     dataField: 'user_id',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Action',
//                     formatter: this.actionFormatter,
//                     parent: this
//                 }
//             ],
//             isLoaded: false,
//             showModal: false
//         }
//         this.activatedFormatter = this.activatedFormatter.bind(this);
//         this.handleShow = this.handleShow.bind(this);
//         this.handleClose = this.handleClose.bind(this);
//         this.handleDelete = this.handleDelete.bind(this);
//         this.loadUsers = this.loadUsers.bind(this);
//     }

//     componentDidMount() {
//         if (!this.props.isLoading) {
//             this.loadUsers();
//         }
//     }

//     loadUsers() {
//         eventEmitter.emit('setLoading', true);
//         fetch('/api/userProfiles')
//         .then(response => response.json())
//         .then((result) => {
//             this.setState({
//                 userList: result,
//                 isLoaded: true
//             })
//             eventEmitter.emit('setLoading', false);
//         })
//     }

//     handleClose() {
//         this.setState({
//             showModal: false
//         })
//     };

//     handleShow(user) {
//         this.setState({
//             selectedUser: user,
//             showModal: true
//         })
//     };

//     handleDelete() {
//         eventEmitter.emit('setLoading', true);
//         fetch(`/api/user/delete/${this.state.selectedUser.user_id}`)
//         .then(response => response.json())
//         .then((result) => {
//             this.handleClose();
//             this.loadUsers();
//             eventEmitter.emit('setLoading', false);
//         })
//     }

//     actionFormatter(cell, row) {
//         return (
//             <ButtonGroup>
//                 <Link className="btn btn-secondary" to={`/admin/users/${row.user_id}`}>Edit</Link>
//                 <Button variant="primary" onClick={() => { this.parent.handleShow(row) }}>Delete</Button>
//             </ButtonGroup>
//         )
//     }

//     activatedFormatter(cell, row) {
//         let retVal = null;
//         if (cell) {
//             retVal = (<span>Yes</span>)
//         } else {
//             retVal = (<span>No</span>)
//         }
//         return retVal;
//     }

//     render() {
//         const { SearchBar, ClearSearchButton } = Search;
//         let retVal = (<div> </div>);

//         if (!this.props.isLoading && this.state.isLoaded) {
//             retVal = (
//                 <div>

//                     <Row className="sub-header p-5">
//                         <Col xs="12" sm="5">
//                             <h1 className="text-left">Users</h1>
//                         </Col>
//                         <Col xs="12" sm="7">
//                             <div className="d-flex justify-content-end align-items-center">
//                                 <div>
//                                     <Link className="btn btn-default" to="/admin/users/create">Create User</Link>
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>

//                     <ToolkitProvider
//                         bootstrap4
//                         keyField="name"
//                         data={this.state.userList}
//                         columns={this.state.userColumns}
//                         search
//                     >
//                         {
//                             props => (
//                                 <div className="data-grid m-4">
//                                     <div className="data-grid-header d-flex justify-content-start">
//                                         <div className="d-flex justify-content-start mr-2">
//                                             <SearchBar {...props.searchProps} />
//                                         </div>
//                                         <div className="d-flex justify-content-start">
//                                             <div><ClearSearchButton {...props.searchProps} /></div>
//                                         </div>
//                                     </div>
//                                     <BootstrapTable 
//                                         {...props.baseProps}
//                                         bordered={this.state.bordered}
//                                         striped={this.state.striped}
//                                         hover={this.state.hover}
//                                         condensed={this.state.condensed}
//                                         pagination={paginationFactory()} 
//                                         noDataIndication="No Users to display"/>
//                                 </div>
//                             )
//                         }
//                     </ToolkitProvider>
//                     <Modal animation={false} show={this.state.showModal} onHide={this.handleClose}>
//                         <Modal.Header closeButton>
//                             <Modal.Title>Delete User Confirmation</Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body>Are you sure you want to delete the user {this.state.selectedUser.name}?</Modal.Body>
//                         <Modal.Footer>
//                             <ButtonGroup>
//                                 <Button variant="secondary" onClick={this.handleClose}>Close</Button>
//                                 <Button variant="primary" onClick={this.handleDelete}>Delete</Button>
//                             </ButtonGroup>
//                         </Modal.Footer>
//                     </Modal>
//                 </div>
//             )
//         }

//         return retVal;
//     }
// }

export default UserList