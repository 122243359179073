import {getCookie, setCookie} from './cookieHelper';

const fetchProfile = async (userId) => {
    let retVal = null;
    try {
        if (getCookie('dftba-profile')) {
            retVal = getCookie('dftba-profile');
        } else {
            let data = await fetch(`${process.env.REACT_APP_API_URL}/api/userProfiles/` + userId)
            let dataJson = await data.json();
            retVal = dataJson[0];
            setCookie('dftba-profile', JSON.stringify(dataJson[0]));
        }
    } catch (err) {
        console.log(err);
        console.log('Failure in fetchProfile');
    }
    return retVal;
}

export {
    fetchProfile
}