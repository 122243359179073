import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export function getCookie(name) {
    return cookies.get(name);
}

export function removeCookie(name) {
    return cookies.remove(name);
}

export function setCookie(name, data) {
    return cookies.set(name, data, {path: '/'})
}
