import React, { useState, useMemo, useEffect } from 'react';
import { Col, Row, Button, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';
import eventEmitter from '../../../helpers/eventEmitter'

function ProductList(props) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = useMemo(() => [
        {
            accessor: 'src',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: '',
            disableSortBy: true,
            Cell: imageFormatter
        }, {
            accessor: 'title',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Title'
        }, {
            accessor: 'vendor',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Vendor'
        }, {
            accessor: 'updated_at',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Last Updated',
            Cell: lastUpdatedFormatter
        }, {
            accessor: 'deleted_at',
            Header: 'Active',
            Cell: deletedFormatter
        }, {
            accessor: 'id',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{ float: 'right' }}>Actions</span>,
            disableSortBy: true,
            Cell: actionFormatter
        }
    ], [])

    function imageFormatter(props) {
        let retVal = null;
        if (props.row.original.src) {
            retVal = (<img src={props.row.original.src} alt={props.row.original.title} style={{ maxHeight: '40px', marginTop: '-2px', borderRadius: '50%' }} />)
        }
        return retVal;
    }

    function actionFormatter(props) {
        let retVal = (<Link className="btn btn-default" to={`/admin/products/${props.row.original.id}`}>Edit</Link>);
        return retVal;
    }

    function lastUpdatedFormatter(props) {
        let updatedDate = new Date(props.value);
        let currentDate = new Date();
        var diff = Math.floor(currentDate.getTime() - updatedDate.getTime());
        var day = 1000 * 60 * 60 * 24;

        var days = Math.floor(diff / day);
        var months = Math.floor(days / 31);
        var years = Math.floor(months / 12);
        let timespan = '';
        let displayNumber = 0;
        if (days <= 31) {
            displayNumber = days;
            timespan = 'day';
        } else if (months < 12) {
            displayNumber = months;
            timespan = 'month';
        } else {
            displayNumber = years;
            timespan = 'year';
        }

        let retVal = (<span>{displayNumber} {timespan}{displayNumber > 1 ? 's' : ''} ago</span>);
        return retVal;
    }

    function deletedFormatter(props) {
        let retVal = (<span>Yes</span>)
        if (props.value) {
            retVal = (<span>No: {new Date(props.value).toLocaleString()}</span>)
        }
        return retVal;
    }

    async function loadData() {
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/products`);
        const rows = await response.json();
        eventEmitter.emit('setLoading', false);
        setRows(rows);
        setDataLoaded(true);
    }

    useEffect(async () => {
        if (!props.isLoading && !dataLoaded) {
            debugger
            await loadData();
        }
    }, [props.isLoading]);

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                <FormControl
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search`}
                />
            </span>
        )
    }

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: {
                pageIndex,
                pageSize,
                globalFilter
            },
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
        } = useTable({
            columns,
            data,            
            initialState: {
                pageSize: 100
            }
        }, useGlobalFilter, useSortBy, usePagination)

        // Render the UI for your table
        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{ padding: '15px 0' }}>
                        <div style={{ float: 'left' }}>
                            <Button variant="default" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </Button>{' '}
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div style={{ float: 'right' }}>
                            <select
                                className="dropdown-toggle btn btn-default"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[100, 200, -1].map(pageSize => (
                                    <option key={pageSize} value={pageSize} style={{color: 'white'}}>
                                        {pageSize > 0 ? pageSize : 'All'}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let retVal = <></>
    if (!props.isLoading && dataLoaded) {
        retVal = <>
            <Row className="sub-header p-5">
                <Col xs="12">
                    <h1 className="text-left">Products</h1>
                </Col>
            </Row>

            <Table columns={columns} data={rows} />
        </>
    }
    return retVal;
}

// class ProductList extends React.Component {
//     constructor(props) {
//         super(props);

//         this.wrapper = React.createRef();

//         this.state = {
//             bordered: false,
//             striped: false,
//             hover: true,
//             condensed: true,
//             wrapperClasses: 'table-responsive',
//             dataLoaded: false,
//             rows: [],
//             columns: [
//                 {
//                     dataField: 'src',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: '',
//                     formatter: this.imageFormatter
//                 }, {
//                     dataField: 'title',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Title',
//                     sort: true
//                 }, {
//                     dataField: 'vendor',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Vendor',
//                     sort: true
//                 }, {
//                     dataField: 'updated_at',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Last Updated',
//                     sort: true,
//                     formatter: this.lastUpdatedFormatter
//                 }, {
//                     dataField: 'deleted_at',
//                     text: 'Is Active',
//                     sort: true,
//                     formatter: this.deletedFormatter
//                 }, {
//                     dataField: 'id',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Actions',
//                     formatter: this.actionFormatter
//                 }
//             ],
//             paginationOptions: {
//                 sizePerPage: 100,
//                 hideSizePerPage: true,
//                 hidePageListOnlyOnePage: true
//             }

//         }
//         this.imageFormatter = this.imageFormatter.bind(this);
//     }

//     componentDidMount() {
//         if (!this.props.isLoading) {
//             eventEmitter.emit('setLoading', true);
//             fetch(`/api/products`)
//                 .then(response => response.json())
//                 .then((result) => {
//                     this.setState({
//                         rows: result,
//                         dataLoaded: true
//                     })
//                     eventEmitter.emit('setLoading', false);
//                 })
//         }
//     }

//     imageFormatter(cell, row) {
//         let retVal = null;
//         if (row.src) {
//             retVal = (<img src={row.src} alt={row.title} style={{ maxHeight: '40px', marginTop: '-2px', borderRadius: '50%' }} />)
//         }
//         return retVal;
//     }

//     actionFormatter(cell, row) {
//         let retVal = (<Link className="btn btn-default" to={`/admin/products/${row.id}`}>Edit</Link>);
//         return retVal;
//     }

//     lastUpdatedFormatter(cell, row) {
//         let updatedDate = new Date(cell);
//         let currentDate = new Date();
//         var diff = Math.floor(currentDate.getTime() - updatedDate.getTime());
//         var day = 1000 * 60 * 60 * 24;

//         var days = Math.floor(diff / day);
//         var months = Math.floor(days / 31);
//         var years = Math.floor(months / 12);
//         let timespan = '';
//         let displayNumber = 0;
//         if (days <= 31) {
//             displayNumber = days;
//             timespan = 'day';
//         } else if (months < 12) {
//             displayNumber = months;
//             timespan = 'month';
//         } else {
//             displayNumber = years;
//             timespan = 'year';
//         }

//         let retVal = (<span>{displayNumber} {timespan}{displayNumber > 1 ? 's' : ''} ago</span>);
//         return retVal;
//     }
//     deletedFormatter(cell, row) {
//         let retVal = (<span>Yes</span>)
//         if (cell) {
//             retVal = (<span>No: {new Date(cell).toLocaleString()}</span>)
//         }
//         return retVal;
//     }

//     render() {
//         const { SearchBar, ClearSearchButton } = Search;
//         let retVal = (<div> </div>);

//         if (!this.state.isLoading && this.state.dataLoaded) {
//             retVal = (
//                 <div ref={this.wrapper}>
//                     <Row className="sub-header p-5">
//                         <Col xs="12">
//                             <h1 className="text-left">Products</h1>
//                         </Col>
//                     </Row>
//                     <ToolkitProvider
//                         bootstrap4
//                         keyField="title"
//                         data={this.state.rows}
//                         columns={this.state.columns}
//                         search
//                     >
//                         {
//                             props => (
//                                 <div className="data-grid mx-4">
//                                     <div className="data-grid-header d-flex justify-content-start">
//                                         <div className="d-flex justify-content-start mr-2">
//                                             <SearchBar {...props.searchProps} />
//                                         </div>
//                                         <div className="d-flex justify-content-start">
//                                             <div><ClearSearchButton {...props.searchProps} /></div>
//                                         </div>
//                                     </div>
//                                     <BootstrapTable
//                                         {...props.baseProps}
//                                         bordered={this.state.bordered}
//                                         striped={this.state.striped}
//                                         hover={this.state.hover}
//                                         condensed={this.state.condensed}
//                                         wrapperClasses={this.state.wrapperClasses}
//                                         pagination={paginationFactory(this.state.paginationOptions)} 
//                                         noDataIndication="No Products in storefront"/>
//                                 </div>
//                             )
//                         }
//                     </ToolkitProvider>
//                 </div>
//             )
//         }

//         return retVal;
//     }
// }
export default ProductList;
