import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { Link } from 'react-router-dom';
import { Col, Row, Button, FormControl } from 'react-bootstrap';
import { withRouter } from "react-router";
import eventEmitter from '../../helpers/eventEmitter'
import ExportCsvButton from '../general/export-csv-button';

function ProductInventory(props) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = useMemo(() => [
        {
            accessor: 'src',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: '',
            //csvExport: false,
            Cell: imageFormatter
        }, {
            accessor: 'product_title',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Product Title',
            //csvText: 'Title',
        }, {
            accessor: 'variant_title',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Variant Title'
        }, {
            accessor: 'published_at',
            //headerClasses: 'text-left',
            className: 'text-left',
            Header: 'Published',
            Cell: publishedFormatter,
            //csvFormatter: (cell, row, rowIndex) => `${cell ? 'TRUE' : 'FALSE'}`
        }, {
            accessor: 'price',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Price</span>,
            Cell: moneyFormatter,
            //csvFormatter: (cell, row, rowIndex) => `$${cell.toFixed(2)}`
        }, {
            accessor: 'percent',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Commission Percentage</span>,
            Cell: percentageFormatter,
            //csvFormatter: (cell, row, rowIndex) => `${cell}%`
        }, {
            accessor: 'flat',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Commission Fee</span>,
            Cell: moneyFormatter,
            //csvFormatter: (cell, row, rowIndex) => `$${cell.toFixed(2)}`
        }, {
            accessor: 'user_id',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Potential Commission</span>,
            Cell: commissionFormatter,
            //csvFormatter: (cell, row, rowIndex) => `$${((row.price * (row.percent / 100)) + row.flat).toFixed(2)}`
        }, {
            accessor: 'inventory_quantity',
            //headerClasses: 'text-right',
            className: 'text-right',
            Header:() => <span style={{float: 'right'}}>Inventory On Hand</span>,
        }, {
            accessor: 'deleted_at',
            Header: 'Active',
            //csvExport: false,
            Cell: deletedFormatter
        }
    ], [])

    function publishedFormatter(cell, row) {
        let retVal = (<span>no</span>);
        if (cell) {
            retVal = (<span>yes</span>)
        }
        return retVal;
    }

    function commissionFormatter(props) {
        let percentage = Number(props.row.original.percent) / 100;
        let value = (Number(props.row.original.price) * percentage) + Number(props.row.original.flat);
        return (
            <span>${value.toFixed(2)}</span>
        )
    }

    function imageFormatter(props) {
        let retVal = null;
        if (props.row.original.picture) {
            retVal = (
                <div>
                    <img src={props.row.original.picture} alt={props.row.original.variant_title} style={{ maxHeight: '20px', marginTop: '-2px', borderRadius: '50%' }} />
                </div>
            )
        }
        return retVal;
    }
    function moneyFormatter(props) {
        return (<span>${Number(props.value).toFixed(2)}</span>)
    }
    function percentageFormatter(props) {
        return (<span>{props.value}%</span>)
    }

    function deletedFormatter(props) {
        let retVal = (<span>Yes</span>)
        if (props.value) {
            retVal = (<span>No: {new Date(props.value).toLocaleString()}</span>)
        }
        return retVal;
    }
    function ReturnLink() {
        let retVal = null;
        if (props.match.params.userId) {
            retVal = (<Link className="btn btn-default dftba-backbutton" to={`/admin/ledger/${props.match.params.userId}`}>Back</Link>)
        }
        return retVal;
    }

    async function loadData(user_id) {
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/product/inventory/${user_id}`);
        const rows = await response.json();
        eventEmitter.emit('setLoading', false);
        setRows(rows);
        setDataLoaded(true);
    }

    useEffect(async () => {
        if (!props.isLoading) {
            let user_id = props.profile.user_id;
            if (props.match.params.userId) {
                user_id = props.match.params.userId;
            }
            await loadData(user_id)
        }
    }, [props.isLoading]);

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                <FormControl
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search`}
                />
            </span>
        )
    }

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            rows,
            prepareRow,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: {
                pageIndex,
                pageSize,
                globalFilter
            },
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
        } = useTable({
            columns,
            data,
            initialState: {
                pageSize: 100
            }
        }, useGlobalFilter, useSortBy, usePagination)

        // Render the UI for your table
        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                    <ExportCsvButton
                        name={"ProductInventory"}
                        tableRows={rows}
                        formatters={['excluded','excluded','excluded','date','','','','money','','excluded','percent','money','excluded','date']}
                    />
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{padding: '15px 0'}}>
                        <div style={{ float: 'left' }}>
                            <Button variant="default" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </Button>{' '}
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div style={{ float: 'right' }}>

                            <select
                                className="dropdown-toggle btn btn-default"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[100, 200, -1].map(pageSize => (
                                    <option key={pageSize} value={pageSize} style={{color: 'white'}}>
                                        {pageSize > 0 ? pageSize : 'All'}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    let retVal = <> </>
    if (!props.isLoading && dataLoaded) {
        retVal = <>
            <Row className="sub-header p-5">
                <Col xs="12" sm="5">
                    <h1 className="text-left">{props.match.params.userId ? '' : 'My'} Inventory</h1>
                </Col>
                <Col xs="12" sm="7">
                    <div>
                        <ReturnLink />
                    </div>
                </Col>
            </Row>
            <Table columns={columns} data={rows} />
        </>
    }
    return retVal;
}

export default withRouter(ProductInventory);
