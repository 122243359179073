import './App.css';
import AppHeader from './components/general/app-header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
import { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { fetchProfile } from './helpers/profileHelper';
import { fetchSettings } from './helpers/settingsHelper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ProductList from './components/admin/products/product-list';
import ProductDetail from './components/admin/products/product-detail';
import LedgerList from './components/admin/ledger/ledger';
import LedgerDetail from './components/general/ledger-detail';
import UserList from './components/admin/users/user-list';
import UserDetail from './components/admin/users/user-detail';
import Welcome from './components/general/welcome';
import SettingsPage from './components/admin/settings/settings';
import ProductInventory from './components/creator/product-inventory';
import TransactionsDetail from './components/creator/transactions-detail';
import GlobalExpenses from './components/admin/expenses/global-expenses';
import {removeCookie} from './helpers/cookieHelper';

function App() {
  const { user, isLoading, loginWithRedirect } = useAuth0();
  const [profile, setProfile] = useState(''); // this creates a getter/setter for profile.
  const [settings, setSettings] = useState('');

  // useEffect is called every time the app is updated (see events at the end user, profile, isLoading), which would include switching pages or submitting forms
  useEffect(async () => {
    // Allows useEffect to run async
    async function profileUpdate() {

      // retrieve settings
      setSettings(await fetchSettings());

      // If useAuth0 is still loading data - skip the sign in check
      if (!isLoading) {
        // If user is present but profile is set to empty, fetch user profile. Otherwise force login
        if (user && profile === '') {
          setProfile(await fetchProfile(user.sub));
        } else if (profile === '') {
          removeCookie('dftba-profile');
          loginWithRedirect();
        }
      }
    }
    await profileUpdate();
  }, [user, profile, isLoading])

  let fullIsLoading = isLoading || profile === '';

  return (
    <div className="App">
      <Router>
        <AppHeader
          profile={profile} />
        <Switch>
          <Route path="/" exact component={() => <Welcome profile={profile} />} />
          <Route path="/admin/ledger" exact component={() => <LedgerList isLoading={fullIsLoading} settings={settings} />} />
          <Route path="/admin/ledger/:userId" component={() => <LedgerDetail isLoading={fullIsLoading} profile={profile} settings={settings} />} />
          <Route path="/admin/inventory/:userId" component={() => <ProductInventory isLoading={fullIsLoading} profile={profile} />} />
          <Route path="/admin/expenses/:userId" exact component={() => <TransactionsDetail isLoading={fullIsLoading} profile={profile} settings={settings} kind='debit'/>} />
          <Route path="/admin/income/:userId" exact component={() => <TransactionsDetail isLoading={fullIsLoading} profile={profile} settings={settings} kind='credit'/>} />
          <Route path="/admin/products" exact component={() => <ProductList />} />
          <Route path="/admin/products/:productId" exact component={() => <ProductDetail isLoading={fullIsLoading} />} />
          <Route path="/admin/expenses" exact component={() => <GlobalExpenses isLoading={fullIsLoading} settings={settings}/>} />
          <Route path="/admin/users" exact component={() => <UserList isLoading={fullIsLoading} />} />
          <Route path="/admin/users/:userId" exact component={() => <UserDetail profile={profile} isLoading={fullIsLoading} />} />
          <Route path="/admin/users/create" exact component={() => <UserDetail profile={profile} isLoading={fullIsLoading} />} />
          <Route path="/admin/settings" exact component={() => <SettingsPage settings={settings} />} />
          <Route path="/creator/dashboard" exact component={() => <LedgerDetail isLoading={fullIsLoading} profile={profile} settings={settings} />} />
          <Route path="/creator/inventory" exact component={() => <ProductInventory isLoading={fullIsLoading} profile={profile} />} />
          <Route path="/creator/expenses" exact component={() => <TransactionsDetail isLoading={fullIsLoading} profile={profile} settings={settings} kind='debit'/>} />
          <Route path="/creator/income" exact component={() => <TransactionsDetail isLoading={fullIsLoading} profile={profile} settings={settings} kind='credit'/>} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
