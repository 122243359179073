import React from 'react';
import DatePicker from 'react-datepicker';
import {
    Button,
    Dropdown
} from 'react-bootstrap'
import { getCurrentCycle, getLastNCycles, setDateSettings, startOfMonth } from '../../helpers/cycle';
import moment from 'moment';

class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);

        let eDate = new Date();
        eDate.setDate(eDate.getDate() + 1);
        eDate = setDateSettings(eDate);

        let sDate = new Date();
        sDate = startOfMonth(sDate);
        sDate.setDate(1);
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);
        sDate.setMilliseconds(0);
        //sDate = setDateSettings(sDate);

        this.state = {
            range: '',
            startDate: sDate,
            endDate: eDate,
            editing: false,
            displayFormat: 'M/D/YY', // 'M/D/YY HH:mm:ss ZZ'
            displayName: `${sDate.toLocaleDateString()} - Today`

        }

        this.displayDate = this.displayDate.bind(this);
        this.handleApplyPeriod = this.handleApplyPeriod.bind(this);
        this.handleApplyCustomPeriod = this.handleApplyCustomPeriod.bind(this);
    }

    componentDidMount() {
        let currentCycle = getCurrentCycle()
        this.setState({ range: currentCycle })
    }

    handleApplyPeriod(start, end, displayName) {
        debugger
        let range = moment.range(start, end)
        this.setState({
            range: range,
            editing: false,
            displayName: displayName
        })
        if (this.props.userId) {
            this.props.onChange(this.props.userId, new Date(start.toDate()), new Date(end.toDate()));
        } else {
            this.props.onChange(new Date(start.toDate()), new Date(end.toDate()));
        }
    }

    handleApplyCustomPeriod() {
        let range = moment.range(this.state.startDate, this.state.endDate);
        let displayName = this.displayDate(range.start, range.end);
        this.setState({
            range: range,
            displayName: displayName
        })
        this.props.onChange(range.start.toDate(), range.end.toDate())
    }

    displayDate(start, end) {
        let startFormat = start.format(this.state.displayFormat)
        let endFormat = end.format(this.state.displayFormat)
        if (end.isSameOrAfter(moment())) {
            endFormat = 'Today'
        }
        return `${startFormat}—${endFormat}`
    }

    setStartDate(date) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(59);
        this.setState({ startDate: date })
    }

    setEndDate(date) {
        this.setState({ endDate: date });
    }

    renderCycles() {
        return getLastNCycles(12, this.props).map((cycle, k) => {
            let { start, end } = cycle
            let display = this.displayDate(start, end)

            return <Dropdown.Item key={k} onClick={() => this.handleApplyPeriod(start, end, display)} className="text-center">
                {this.displayDate(start, end)}
                {k === 0 && <small className="text-muted">
                    <br />(Current cycle {end.isSameOrAfter(moment()) && `ends ${moment().to(end)}`})
            </small>}
            </Dropdown.Item>
        })
    }

    render() {
        return (
            <div id="custom-date-picker">
                <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                        {this.state.displayName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {this.renderCycles()}
                        <Dropdown.Divider />
                        <div className="d-flex">
                            <DatePicker
                                dateFormat={'M/d/yy'}
                                selected={this.state.startDate}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                className="form-control w-96"
                                onChange={date => this.setStartDate(date)}
                            />
                            <DatePicker
                                dateFormat={'M/d/yy'}
                                selected={this.state.endDate}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                className="form-control w-96"
                                onChange={date => this.setState({ endDate: date })} />
                            <div className="input-group-btn">
                                <Button variant="default" onClick={() => { }} >X</Button>
                                <Button variant="primary" onClick={() => { this.handleApplyCustomPeriod() }}>Apply</Button>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>);
    }
}

export default CustomDatePicker
