import { useState, useEffect } from 'react'
import {
    Navbar,
    Nav,
    NavDropdown,
    Image
} from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

import Icon from './icon';

import { removeCookie } from '../../helpers/cookieHelper';
import eventEmitter from '../../helpers/eventEmitter'

const AppHeader = function (props) {
    const { logout } = useAuth0();
    const { profile } = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        eventEmitter.on('setLoading', (val) => {
            setLoading(val);
        });

    }, () => {
        eventEmitter.off('setLoading');
    }, []);

    const user = profile ? (<span>
        <Image src={profile.picture} style={{ maxHeight: '20px', marginTop: '-2px', borderRadius: '50%' }} />
        &nbsp;{profile.user_metadata ? profile.user_metadata.name : profile.email}
    </span>) : null;

    return (
        <Navbar className="dftba navbar-inverse" expand="md" variant="dark" fixed="top">
            <Navbar.Brand className="dftba-brand" href={profile && profile.app_metadata.role === 'admin' ? '/admin/ledger' : '/creator/dashboard'}>
                <span className="dftba-brand-logo">DFTBA</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="app-navbar-nav">
                <Nav className="mr-auto font-weight-bold text-uppercase">
                    {(profile && profile.app_metadata.role === 'admin') && <Link className="nav-link" to="/admin/ledger"><Icon type="bar-chart" className="mr-2" /> Ledger</Link>}
                    {(profile && profile.app_metadata.role === 'admin') && <Link className="nav-link" to="/admin/products"><Icon type="shopping-cart" className="mr-2" /> Products</Link>}
                    {(profile && profile.app_metadata.role === 'admin') && <Link className="nav-link" to="/admin/expenses"><Icon type="arrow-circle-o-up" className="mr-2" /> Expenses</Link>}
                    {(profile && profile.app_metadata.role !== 'admin') && <Link className="nav-link" to="/creator/dashboard"><Icon type="dashboard" className="mr-2" /> Dashboard</Link>}
                    {(profile && profile.app_metadata.role !== 'admin') && <Link className="nav-link" to="/creator/inventory"><Icon type="cubes" className="mr-2" /> My Inventory</Link>}
                    {(profile && profile.app_metadata.role !== 'admin') && <Link className="nav-link" to="/creator/expenses"><Icon type="arrow-circle-o-up" className="mr-2" /> Expenses</Link>}
                    {(profile && profile.app_metadata.role !== 'admin') && <Link className="nav-link" to="/creator/income"><Icon type="arrow-circle-o-down" className="mr-2" /> Income</Link>}
                    {/* <span className='nav-link' style={{ color: 'red', textAlign: 'center' }}>TEST SITE {profile && profile.app_metadata.role === 'admin' ? 'ADMIN' : 'CREATOR'}</span> */}
                </Nav>

                <Nav className="navbar-right">
                    {(profile && profile.app_metadata.role === 'admin') && <Link className="nav-link" to="/admin/settings"><Icon type="cog" className="mr-2" /> Settings</Link>}
                    {(profile && profile.app_metadata.role === 'admin') && <Link className="nav-link" to="/admin/users"><Icon type="users" className="mr-2" /> Users</Link>}
                    <NavDropdown title={user} id="collasible-nav-dropdown" className="font-weight-bold text-uppercase">
                        <Link className="dropdown-item" to={`/admin/users/${profile.user_id}`}>Edit Profile</Link>
                        <NavDropdown.Item onClick={() => { removeCookie('dftba-profile'); logout({ returnTo: window.location.origin }) }}>Sign Out <Icon type="sign-out" className="ml-2" /> </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                {loading && <Nav className="navbar-right loader-container">
                    <div className="loader loader-active"><div className="loader-inner line-scale">
                        <div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                </Nav>}
            </Navbar.Collapse>
        </Navbar>
    )
}

export default AppHeader;
