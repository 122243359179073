import React from 'react';
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'

import {removeCookie} from '../../../helpers/cookieHelper';
import eventEmitter from '../../../helpers/eventEmitter'

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalSettings: props.settings,
            day: props.settings.day,
            hours: props.settings.hours,
            minutes: props.settings.minutes,
            offset: props.settings.offset,
            timeCycle: props.settings.timeCycle
        }

        this.renderDays = this.renderDays.bind(this);
        this.renderHours = this.renderHours.bind(this);
        this.renderMinutes = this.renderMinutes.bind(this);
        this.renderOffset = this.renderOffset.bind(this);
        this.updateDay = this.updateDay.bind(this);
        this.updateHours = this.updateHours.bind(this);
        this.updateMinutes = this.updateMinutes.bind(this);
        this.updateOffset = this.updateOffset.bind(this);
        this.resetSettings = this.resetSettings.bind(this);
    }

    updateDay(event) {
        
        this.setState({
            day: event.target.value
        })
    }

    updateHours(event) {
        
        this.setState({
            hours: event.target.value
        })
    }

    updateMinutes(event) {
        
        this.setState({
            minutes: event.target.value
        })
    }

    updateOffset(event) {
        
        this.setState({
            offset: event.target.value
        })
    }

    resetSettings() {
        this.setState({
            day: this.state.originalSettings.day,
            hours: this.state.originalSettings.hours,
            minutes: this.state.originalSettings.minutes,
            offset: this.state.originalSettings.offset,
        })
    }

    submitSettings() {
        let { day, hours, minutes, offset } = this.state;
        eventEmitter.emit('setLoading', true);
        fetch(`/api/settings/${day}/${hours}/${minutes}/${offset}`)
            .then(response => response.json())
            .then((result) => {
                removeCookie('dftba-settings');
                eventEmitter.emit('setLoading', false);
                window.location.reload(false);
            })
    }

    checkChanged() {
        
        return this.state.day === this.state.originalSettings.day &&
            this.state.hours === this.state.originalSettings.hours &&
            this.state.minutes === this.state.originalSettings.minutes &&
            this.state.offset === this.state.originalSettings.offset
    }

    renderDays() {
        let retVal = []
        for (let i = 0; i < 32; i++) {
            retVal.push((<option key={'hours-' + i}>{i}</option>))
        }
        return retVal;
    }

    renderHours() {
        let retVal = []
        for (let i = 0; i < 24; i++) {
            retVal.push((<option key={'hours-' + i}>{i}</option>))
        }
        return retVal;
    }

    renderMinutes() {
        let retVal = []
        for (let i = 0; i < 60; i++) {
            retVal.push((<option key={'minutes-' + i}>{i}</option>))
        }
        return retVal;
    }

    renderOffset() {
        let retVal = []

        for (let i = 0; i < 13; i++) {
            retVal.push((<option key={'offset-' + i} >{'-' + this.addZero(i) + i + ':00'}</option>))
        }
        retVal.push((<option key={'offset 0'} value="00:00">UTC</option>));
        for (let i = 0; i < 13; i++) {
            retVal.push((<option key={'offset+' + i} >{'+' + this.addZero(i) + i + ':00'}</option>))
        }

        return retVal;
    }

    addZero(i) {
        return Math.abs(i) < 10 ? '0' : ''
    }

    render() {
        let settingsChanged = this.checkChanged();
        return (
            <div>
                <Row className="sub-header p-5">
                    <Col xs="12" sm="5">
                        <h1 className="text-left">Settings</h1>
                    </Col>
                    <Col xs="12" sm="7">
                        <div className="d-flex justify-content-end align-items-center">
                            <div>
                              
                            </div>
                        </div>
                    </Col>
                </Row>

                <Form className="m-5">
                    <Form.Label className="text-left d-block">Sales Period Settings</Form.Label>
                    <Form.Group controlId="settingsForm.Day">
                        <Form.Label className="text-left d-block">Day</Form.Label>
                        <Form.Control as="select" onChange={this.updateDay} defaultValue={this.state.day} value={this.state.day}>
                            {this.renderDays()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="settingsForm.TimeOfDay">
                        <Form.Label className="text-left d-block">Hour</Form.Label>
                        <Form.Control as="select" onChange={this.updateHours} defaultValue={this.state.hours} value={this.state.hours}>
                            {this.renderHours()}
                        </Form.Control>
                        <Form.Label className="text-left d-block">Mintues</Form.Label>
                        <Form.Control as="select" onChange={this.updateMinutes} defaultValue={this.state.minutes} value={this.state.minutes}>
                            {this.renderMinutes()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="settingsForm.Offset">
                        <Form.Label className="text-left d-block">Offset</Form.Label>
                        <Form.Control as="select" onChange={this.updateOffset} defaultValue={this.state.offset} value={this.state.offset}>
                            {this.renderOffset()}
                        </Form.Control>
                    </Form.Group>

                    <hr />
                    <div className="d-flex justify-content-starts mt-4">
                        <ButtonGroup>
                            <Button variant="secondary" disabled={settingsChanged} onClick={() => { this.resetSettings() }}>Reset</Button>
                            <Button variant="primary" disabled={settingsChanged} onClick={() => { this.submitSettings() }}>Save</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </div>
        )
    }
}

export default SettingsPage;
