import React from 'react'
import { Col, Modal, Button, ButtonGroup, Form, InputGroup, FormControl } from 'react-bootstrap';
import eventEmitter from '../../helpers/eventEmitter'

class AddTransactionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            rows: [],
            selectedUser: props.userId ? props.userId : null,
            amount: 0.00,
            description: null,
            isInvalid: false,
            loading: true,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleCreators = this.handleCreators.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.changeUser = this.changeUser.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.changeDescription = this.changeDescription.bind(this);

        this.checkKind = this.checkKind.bind(this);
        this.checkConfirmButton = this.checkConfirmButton.bind(this);
    }

    componentDidMount() {
        eventEmitter.emit('setLoading', true);
        if (!this.props.userId) {
            fetch('/api/getAllCreators')
                .then(response => response.json())
                .then((result) => {
                    this.setState({
                        rows: result,
                        loading: false
                    })
                    eventEmitter.emit('setLoading', false);
                })
        } else {
            fetch(`/api/getCreatorById/${this.props.userId}`)
                .then(response => response.json())
                .then((result) => {

                    this.setState({
                        rows: result,
                        loading: false
                    })
                    eventEmitter.emit('setLoading', false);
                })
        }
    }

    changeUser(event) {
        this.setState({
            selectedUser: event.target.value
        })
    }
    changeAmount(event) {
        if (isNaN(event.target.value)) {
            this.setState({
                isInvalid: true
            })
        } else {
            this.setState({
                amount: event.target.value,
                isInvalid: false
            })
        }
    }
    changeDescription(event) {
        this.setState({
            description: event.target.value
        })
    }

    handleClose() {
        this.setState({
            showModal: false
        })
    };
    handleShow() {
        this.setState({
            showModal: true
        })
    };

    handleCreators() {
        let retVal = [];
        if (!this.props.userId) {
            retVal.push((<option key={this.props.kind + 'option-'} value="-1">Choose one...</option>))
        }
        for (let i = 0; i < this.state.rows.length; i++) {
            let creator = this.state.rows[i];
            retVal.push((<option key={this.props.kind + 'option-' + i} style={{ color: 'black' }} value={creator.user_id}>{creator.user_name}</option>))
        }
        return retVal;
    }

    handleSubmit() {

        let { selectedUser, amount, description } = this.state;
        fetch(`/api/insertTransaction/${selectedUser}/${this.props.kind}/${amount}/${description}`)
            .then(response => response.json())
            .then((result) => {
                this.handleClose();
                this.props.onChange();
            })
    }

    checkKind() {
        return this.props.kind === "Expense";
    }

    renderForm() {
        let retVal = null;
        if (this.state.loading) {
            retVal = (<div> </div>)
        } else {
            retVal = (
                <Form>
                    <Form.Row className="mb-3">
                        <Col>
                            <Form.Label>Creator</Form.Label>
                            <Form.Control as="select" id="creator" onChange={this.changeUser}>
                                {this.handleCreators()}

                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Amount</Form.Label>
                            <InputGroup>
                                <span className="input-group-addon">{this.checkKind() ? '- $' : '$'}</span>
                                <FormControl id="amount" placeholder="0.00" onChange={this.changeAmount} isInvalid={this.state.isInvalid} />
                            </InputGroup>
                        </Col>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={this.changeDescription} />
                    </Form.Group>
                </Form>)
        }
        return retVal;
    }

    checkConfirmButton() {
        return this.state.amount <= 0 || this.state.isInvalid || this.state.selectedUser === null || this.state.selectedUser === -1
    }

    render() {
        let isDisabled = this.checkConfirmButton()
        return (
            <div ref={this.wrapper}>
                <Button variant="default" onClick={this.handleShow}>Add {this.props.kind}</Button>

                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create {this.props.kind}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderForm()}
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                            <Button variant="primary" onClick={this.handleSubmit} disabled={isDisabled}>Confirm</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default AddTransactionButton;