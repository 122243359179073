import {getCookie, setCookie} from './cookieHelper';

const fetchSettings = async () => {
    let retVal = {};
    let enableSettingsCache = false; // disable caching for now until the setting is set to 1 in prod and won't change
    try {
        if (getCookie('dftba-settings') && enableSettingsCache) {
            retVal = getCookie('dftba-settings');
        } else {
            let data = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/`)
            let dataJson = await data.json();
            for (let i = 0; i < dataJson.length; i++) {
                let setting = dataJson[i];
                switch (setting.key) {
                    case 'day':
                        retVal.day = setting.value;
                        break;
                    case 'hours':
                        retVal.hours = setting.value;
                        break;
                    case 'minutes':
                        retVal.minutes = setting.value;
                        break;
                    case 'offset':
                        retVal.offset = setting.value;
                        break;
                    case 'cycleTime':
                        retVal.cycleTime = setting.value;
                        break;
                    default:
                }
            }
            setCookie('dftba-settings', JSON.stringify(retVal));
        }
    } catch (err) {
        console.log(err);
        console.log('Failure in fetchProfile');
    }
    return retVal;
}

export {
    fetchSettings
}