import React, { useState, useMemo, useEffect } from 'react';
import { Col, Row, Button, FormControl } from 'react-bootstrap';
import moment from 'moment';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';

import CustomDatePicker from '../../general/date-picker';
import { setDateSettings, setFullDateSettings, formatDateForQuery, startOfMonth } from '../../../helpers/cycle';
import eventEmitter from '../../../helpers/eventEmitter'
import ExportCsvButton from '../../general/export-csv-button';

function GlobalExpenses(props) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const [startDate, setStartDate] = useState();
    const columns = useMemo(() => [
        {
            accessor: 'name',
            className: 'text-left',
            Header: 'Creator',
            sort: true
        }, {
            accessor: 'kind',
            className: 'text-left',
            Header: 'Kind',
            Cell: props => <span style={{ display: 'block' }} className="label label-default">{props.value}</span>
        }, {
            accessor: 'created_at',
            className: 'text-left',
            Header: 'Created On',
            Cell: props => <span>{new Date(props.value).toLocaleString()}</span>
        }, {
            accessor: 'description',
            className: 'text-left',
            Header: 'Description'
        }, {
            accessor: 'amount',
            className: 'text-right',
            Header: () => <span style={{float: 'right'}}>Amount</span>,
            Cell: props => <span className="text-right">${(props.value / 100).toFixed(2)}</span>
        }
    ], [])

    async function loadData(startDate, endDate) {
        eventEmitter.emit('setLoading', true);
        const response = await fetch(`/api/transactions/expenses/${formatDateForQuery(startDate, false)}/${formatDateForQuery(endDate, true)}`)
        const rows = await response.json();
        eventEmitter.emit('setLoading', false);
        setRows(rows);
        setDataLoaded(true);
    }

    useEffect(async () => {
        if (!props.isLoading && !dataLoaded) {
            let eDate = new Date();
            eDate.setDate(eDate.getDate() + 1);
            eDate = setDateSettings(eDate)

            let sDate = new Date();
            sDate = startOfMonth(sDate);
            sDate = setFullDateSettings(sDate, props.settings);
            await loadData(sDate, eDate, props);
            setStartDate(sDate);
        }
    }, [props.isLoading]);

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                <FormControl
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search`}
                />
            </span>
        )
    }

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: {
                pageIndex,
                pageSize,
                globalFilter
            },
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            rows
        } = useTable({
            columns,
            data,
            initialState: {
                pageSize: 100
            }
        }, useGlobalFilter, useSortBy, usePagination)

        // Render the UI for your table
        return (
            <div className="data-grid m-4">
                <div className="data-grid-header d-flex justify-content-start">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                    <ExportCsvButton 
                        name="GlobalExpenses"
                        tableRows={rows}
                        formatters={["","","date","","money"]}/>
                </div>
                <div className="react-bootstrap-table table-responsive">
                    <table {...getTableProps()} className="table table-hover table-sm">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{padding: '15px 0'}}>
                        <div style={{ float: 'left' }}>
                            <Button variant="default" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </Button>{' '}
                            <Button variant="default" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </Button>{' '}
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                        <div>
                            <div style={{ float: 'right' }}>
                                <select
                                    className="dropdown-toggle btn btn-default"
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                                >
                                {[100, 200, -1].map(pageSize => (
                                    <option key={pageSize} value={pageSize} style={{color: 'white'}}>
                                        {pageSize > 0 ? pageSize : 'All'}
                                    </option>
                                ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let retVal = (
        <>
            <Row className="sub-header p-5">
                <Col xs="12" sm="5">
                    <h1 className="text-left">Global Expenses</h1>
                </Col>
                <Col xs="12" sm="7">
                </Col>
            </Row>
        </>)
        ;
    if (!props.isLoading && dataLoaded) {
        retVal = (
            <>
                <Row className="sub-header p-5">
                    <Col xs="12" sm="5">
                        <h1 className="text-left">Global Expenses</h1>
                    </Col>
                    <Col xs="12" sm="7">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="mr-2">
                                <CustomDatePicker onChange={loadData} cycleTime={moment(startDate).toString()} settings={props.settings} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Table columns={columns} data={rows} />
            </>
        )
    }
    return retVal;
}

// class GlobalExpenses extends React.Component {
//     constructor(props) {
//         super(props);
//         let eDate = new Date();
//         eDate.setDate(eDate.getDate() + 1);
//         eDate = setDateSettings(eDate, props.settings)

//         let sDate = new Date();
//         if(sDate.getDate() < props.settings.day) {
//             sDate.setMonth(sDate.getMonth() - 1);
//         }
//         sDate = setFullDateSettings(sDate, props.settings);
//         this.wrapper = React.createRef();
//         this.state = {
//             bordered: false,
//             striped: false,
//             hover: true,
//             condensed: true,
//             wrapperClasses: 'table-responsive',
//             dataLoaded: false,
//             paginationOptions: {
//                 sizePerPage: 100,
//                 sizePerPageList: [
//                     { text: '25', value: 25 },
//                     { text: '50', value: 50 },
//                     { text: '100', value: 100 },
//                     { text: '500', value: 500 }]
//             },
//             rows: [],
//             columns: [
//                 {
//                     dataField: 'name',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Creator',
//                     sort: true
//                 }, {
//                     dataField: 'kind',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Kind'
//                 }, {
//                     dataField: 'created_at',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Created On',
//                     sort: true,
//                     formatter: this.dateFormatter
//                 }, {
//                     dataField: 'description',
//                     headerClasses: 'text-left',
//                     classes: 'text-left',
//                     text: 'Description'
//                 }, {
//                     dataField: 'amount',
//                     headerClasses: 'text-right',
//                     classes: 'text-right',
//                     text: 'Amount',
//                     sort: true,
//                     formatter: this.moneyFormatter
//                 }
//             ],
//             endDate: eDate,
//             startDate: sDate
//         }
//         this.reloadData = this.reloadData.bind(this);
//     }

//     componentDidMount() {
//         if(!this.props.isLoading) {
//             this.loadData();
//         }
//     }

//     loadData() {
//         eventEmitter.emit('setLoading', true);
//         fetch(`/api/transactions/expenses/${formatDateForQuery(this.state.startDate)}/${formatDateForQuery(this.state.endDate)}`)
//         .then(response => response.json())
//         .then((result) => {
//             result.map((i) => { 
//                 i.amount = Number(i.amount);
//                 i.name = i.name.trim();
//                 return i;
//             })
//             this.setState({
//                 rows: result,
//                 dataLoaded: true
//             })
//             eventEmitter.emit('setLoading', false);
//         })
//     }

//     reloadData(startDate, endDate) {
//         eventEmitter.emit('setLoading', true);
//         fetch(`/api/transactions/expenses/${formatDateForQuery(startDate)}/${formatDateForQuery(endDate)}`)
//         .then(response => response.json())
//         .then((result) => {
//             result.map((i) => { 
//                 i.amount = Number(i.amount);
//                 i.name = i.name.trim();
//                 return i;
//             })
//             this.setState({
//                 rows: result,
//                 dataLoaded: true,
//                 startDate: startDate,
//                 endDate: endDate
//             })
//             eventEmitter.emit('setLoading', false);
//         })
//     }

//     moneyFormatter(cell, row) {
//         return (<span>${(cell / 100).toFixed(2)}</span>)
//     }
//     dateFormatter(cell, row) {
//         return (<span>{new Date(cell).toLocaleString('en-GB')}</span>)
//     }

//     render() {
//         const { SearchBar, ClearSearchButton } = Search;
//         const { ExportCSVButton } = CSVExport;
//         let payPeriodDate = new Date();
//         payPeriodDate = setFullDateSettings(payPeriodDate, this.props.settings);

//         let retVal = (<div> </div>);

//         if(!this.props.isLoading && this.state.dataLoaded) {
//             retVal = (
//                 <div>
//                     <Row className="sub-header p-5">
//                         <Col xs="12" sm="5">
//                             <h1 className="text-left">Expenses</h1>
//                         </Col>
//                         <Col xs="12" sm="7">
//                             <div className="d-flex justify-content-end align-items-center">
//                                 <div>
//                                    <CustomDatePicker onChange={this.reloadData} cycleTime={moment(payPeriodDate).toString()} settings={this.props.settings}/>
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                     <ToolkitProvider
//                         bootstrap4
//                         keyField="id"
//                         data={this.state.rows}
//                         columns={this.state.columns}
//                         search
//                         exportCSV={{
//                             fileName: `dftba-commission-export-${new Date().toLocaleDateString()}.csv`
//                         }}
//                                             >
//                         {
//                             props => (
//                                 <div className="data-grid m-4">
//                                     <div className="data-grid-header d-flex justify-content-start">

//                                         <div className="d-flex justify-content-start mr-2">
//                                             <SearchBar {...props.searchProps} />
//                                         </div>
//                                         <div className="d-flex justify-content-start">
//                                             <div><ClearSearchButton {...props.searchProps} className="mr-2" /></div>
//                                             <div className="mr-2"><ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton></div>
//                                         </div>
//                                     </div>
//                                     <BootstrapTable 
//                                         {...props.baseProps}
//                                         bordered={this.state.bordered}
//                                         striped={this.state.striped}
//                                         hover={this.state.hover}
//                                         condensed={this.state.condensed}
//                                         wrapperClasses={this.state.wrapperClasses} 
//                                         pagination={paginationFactory()}
//                                         noDataIndication={`No ${this.props.kind === 'credit' ? 'income' : 'expenses'} for you during the pay period of ${this.state.startDate.toLocaleDateString()} - ${this.state.endDate.toLocaleDateString()}`}
// />
//                                 </div>
//                             )
//                         }
//                     </ToolkitProvider>
//                 </div>
//             )
//         }

//         return retVal;
//     }
// }

export default GlobalExpenses;