import React from 'react'
import { Link } from 'react-router-dom';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import eventEmitter from '../../../helpers/eventEmitter';
import {setDateSettings} from '../../../helpers/cycle';

class ActionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selectedRow: null
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handlePayout = this.handlePayout.bind(this);
    }

    handleClose() {
        this.setState({
            selectedRow: this.props.row,
            showModal: false
        })
    };
    handleShow() {
        this.setState({
            selectedRow: this.props.row,
            showModal: true
        })
    };
    handlePayout() {
        eventEmitter.emit('setLoading', true);
        let {user_id, net, startdate, enddate} = this.props.row;
        fetch(`/api/ledger/payout/${user_id}/${net * -1}/${enddate}`)
        .then(response => response.json())
        .then((result) => {
            this.handleClose();
            this.props.onChange(this.props.startDate, this.props.endDate);
            eventEmitter.emit('setLoading', false);
        })
    }

    render() {
        return (
            <div>
                <ButtonGroup>
                   <Link className="btn btn-default" to={`/admin/ledger/${this.props.row.user_id}`}>Detail</Link>
                   <Button variant="primary" onClick={this.handleShow} disabled={this.props.row.net <= 0}>Payout</Button>
                </ButtonGroup>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payout Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to pay out {this.props.row.user_name}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                        <Button variant="primary" onClick={this.handlePayout}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ActionButton;