

function ProductView(props) {

    let retVal = (
        <div>
            <figure>
                {props.product.src && <img src={props.product.src} alt={props.product.title} className={'img-thumbnail'}/>}
            </figure>
            <div className="table-responsive mt-4">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>Price</td>
                            <td>{props.product.price}</td>
                        </tr>
                        <tr>
                            <td>Vendor</td>
                            <td>{props.product.product_type}</td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>{props.product.product_type}</td>
                        </tr>
                        <tr>
                            <td>Published</td>
                            <td>{props.product.published_at ? 'Yes' : 'No'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    return retVal;
}

export default ProductView;