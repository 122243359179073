import {Redirect} from 'react-router-dom';

function Welcome(props) {
    let retVal = (<div></div>)
    if(props.profile !== '') {
        debugger
        if(props.profile.app_metadata.role === 'admin') {
            retVal = (<Redirect to="/admin/ledger"/>);
        } else {
            retVal = (<Redirect to="/creator/dashboard"/>);
        }
    }
    return retVal;
}

export default Welcome